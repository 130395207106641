import { useState, useEffect } from 'react';

export const useIsLandscape = () => {
  const [isLandscape, setIsLandscape] = useState(
    window.innerHeight < window.innerWidth
  );

  useEffect(() => {
    const setOrientation = () => {
      setIsLandscape(window.innerHeight < window.innerWidth);
    };
    window.addEventListener('resize', setOrientation);

    return () => {
      window.removeEventListener('resize', setOrientation);
    };
  }, []);

  return isLandscape;
};
