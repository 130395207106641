import React, { memo, useCallback, useContext } from 'react'
import { round, min, max } from 'mathjs'
import { AllInputDataContext } from '../../../config'
import styles from './MobileTechnologyController.module.css'

const MobileTechnologyController = memo(
  ({
    value, //Need sliderValu
    techIndexNumber, //Need to Remove
    incrementAmmount,
    setSliderValues,
    handleInputChange
  }) => {
    const { technologyData } = useContext(AllInputDataContext)
    const { incrementBy } = incrementAmmount

    const handleIncreaseDecreaseCapacity = useCallback(
      isIncrease => {
        setSliderValues(sliderValues => {
          let values = [...sliderValues]
          const currentValue = values[techIndexNumber]
          const addValue = isIncrease ? incrementBy : incrementBy * -1
          values[techIndexNumber] = max(
            0,
            min(
              round(currentValue + addValue, 1),
              technologyData[techIndexNumber].capMax
            )
          )

          return values
        })
      },
      [incrementBy, setSliderValues, techIndexNumber, technologyData]
    )

    return (
      <div className={styles.container}>
        <button
          style={{ backgroundColor: technologyData[techIndexNumber].color }}
          className={styles.button}
          onClick={() => handleIncreaseDecreaseCapacity(false)}
        >
          <div
            style={{
              color:
                technologyData[techIndexNumber].name !== 'Solar'
                  ? 'white'
                  : 'black'
            }}
            className={styles.sign}
          >
            -
          </div>
        </button>
        <div className={styles.labelContainer}>
          <div className={styles.label}>
            {techIndexNumber === 10
              ? 'Pump Store'
              : technologyData[techIndexNumber].name}
          </div>
          <div className={styles.capacityContainer}>
            <input
              className={styles.capacityValue}
              type='number'
              min='0'
              step='any'
              value={value}
              onChange={event =>
                handleInputChange(+event.target.value, techIndexNumber)
              }
              inputMode='decimal'
              pattern='\d*'
            />
            <span className={styles.capacitySuffix}>GW</span>
          </div>
        </div>
        <button
          style={{ backgroundColor: technologyData[techIndexNumber].color }}
          className={styles.button}
          onClick={() => handleIncreaseDecreaseCapacity(true)}
        >
          <div
            style={{
              color:
                technologyData[techIndexNumber].name !== 'Solar'
                  ? 'white'
                  : 'black'
            }}
            className={styles.sign}
          >
            +
          </div>
        </button>
      </div>
    )
  }
)
export default MobileTechnologyController
