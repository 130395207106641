import { memo } from "react";
import styles from "./MEGSegment.module.css";
import { Link, useHistory } from "react-router-dom";
import { ImgWithFallback } from "../../../functions/ImgWithFallback";

export const MEGSSegment = memo(() => {
  let history = useHistory();
  return (
    <div id="MEGS" className={styles.container}>
      <ImgWithFallback
        src={"/images/landingPage/MEGS_ICON.webp"}
        fallback={"/images/landingPage/MEGS_ICON.png"}
        alt="Megs Icon Logo"
        className={styles.backgroundIcon}
      />
      <ImgWithFallback
        src={"/images/landingPage/megs_background.webp"}
        fallback={"/images/landingPage/megs_background.png"}
        alt="Megs Background"
        className={styles.backgroundGraph}
      />
      <ImgWithFallback
        src={"/images/landingPage/MEGS_Logo_1_White.webp"}
        fallback={"/images/landingPage/MEGS_Logo_1_White.png"}
        alt="Megs White Logo"
        className={styles.headingImg}
      />
      <div className={styles.contentContainer}>
        <div className={styles.mainImageContainer}>
          {" "}
          <ImgWithFallback
            onClick={() => history.push("/MEGS")}
            src={"/images/landingPage/megs.webp"}
            fallback={"/images/landingPage/megs.png"}
            alt="Megs"
            className={styles.img}
          />
        </div>
        <div className={styles.textContent}>
          <h1>Modelling Energy and Grid Services</h1>
          <p>
            MEGS models three components essential for the delivery of
            electricity, Energy, Frequency Response and Inertia, the latter two
            being essential grid services. A detailed examination of the
            algorithm can be found in this{" "}
            <Link
              style={{ color: "black" }}
              to="/publications/#MEGS:%20Modelling%20energy%20and%20grid%20services%20to%20explore%20decarbonisation%20of%20power%20systems%20at%20lowest%20total%20system%20cost"
            >
              paper
            </Link>
            .
            <br /> <br />
            By clicking the chart opposite you can explore the results of 3,300
            scenarios for a low carbon grid in Australia’s National Electricity
            Market, in particular seeing how they compare on a cost vs
            decarbonisation chart. You can generate your own subset of scenarios
            by applying technology constraints, such as ”no nuclear and no coal”
            or ”renewables {">"} 50GW” and see how it affects the lowest cost
            frontier. The data and methodology are explained in more detail in
            this{" "}
            <Link
              style={{ color: "black" }}
              to="/publications/#The%20Lowest%20Total%20System%20Cost%20NEM:%20The%20impact%20of%20constraints"
            >
              report
            </Link>
            .
          </p>
          <div className={styles.buttonRow}>
            <div className={styles.buttons}>
              <button onClick={() => history.push("/MEGS")}>
                Start from scratch
              </button>{" "}
              <button
                onClick={() =>
                  history.push({
                    pathname: "/MEGS",
                    search:
                      "?page=charts&version=educational&country=aus&year=2050&allRES=0,50"
                  })
                }
              >
                Renewables limit of 50GW
              </button>{" "}
            </div>{" "}
            <div className={styles.buttons}>
              <button
                onClick={() =>
                  history.push({
                    pathname: "/MEGS",
                    search:
                      "?page=charts&version=educational&country=aus&year=2050&allCCS=0,0"
                  })
                }
              >
                No CCS
              </button>{" "}
              <button
                onClick={() =>
                  history.push({
                    pathname: "/MEGS",
                    search:
                      "?page=charts&version=educational&country=aus&year=2050&allCCS=0,0&nuclear=0,0"
                  })
                }
              >
                No CCS and no nuclear
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
