import React, { useContext, memo } from 'react'
import { Bar } from 'react-chartjs-2'
import { reserveChartOption, reserveChartData } from './reserveChartData'

import { CalculatedDataContext, AllInputDataContext } from '../../../config'
import allStyles from './../AllCharts.module.css'
import styles from './ReserveChart.module.css'

const ReserveChart = memo(() => {
  const {
    reservesAvali,
    dataIndex,
    annualConst,
    reserveRemaining,
    firstRunValues
  } = useContext(CalculatedDataContext)
  const { optionsData } = useContext(AllInputDataContext)

  const chartData = reserveChartData({
    reservesAvali,
    dataIndex,
    optionsData,
    annualConst,
    reserveRemaining,
    firstRunValues
  })
  const chartOptions = reserveChartOption()

  const displayText = () => {
    if (reserveRemaining > 10) {
      return <p className={styles.systemGood}>SYSTEM GOOD</p>
    }
    if (reserveRemaining > 0 && reserveRemaining < 15) {
      return <p className={styles.reserveShortfall}>RESERVE SHORTFALL</p>
    }
    if (reserveRemaining <= 0) {
      return <p className={styles.blackout}>BLACKOUT</p>
    }
  }

  // console.log(chartData)
  return (
    <div className={allStyles.dataChart}>
      <div className={allStyles.chartInnerCard}>
        {displayText()}
        <Bar data={chartData} options={chartOptions} />
      </div>
    </div>
  )
})
export default ReserveChart
