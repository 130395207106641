import type { ChartData, ChartOptions } from "chart.js";

export function carbonCostChartOption(): ChartOptions {
  return {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          offset: false
        }
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: "Percentage Of 2020",
          padding: -2,
          font: { size: 14 }
        }
      }
    }
  };
}

interface ICarbonCostChartData {
  annualConst: any;
  optionsData: any;
}
export function carbonCostChartData({
  annualConst,
  optionsData
}: ICarbonCostChartData): ChartData {
  const chartLabels = [
    [
      "Carbon",
      "Intensity",
      " " + annualConst.totalAnnualCarbon.toFixed(3) + " t/MWh"
    ],
    [
      "Wholesale",
      "Cost",
      " " +
        annualConst.totalAnnualCost.toFixed(1) +
        " " +
        optionsData.currency +
        "/MWh"
    ]
  ];

  return {
    labels: chartLabels,
    datasets: generateDatasets({ annualConst })
  };
}
interface IGenerationDatasets {
  annualConst: any;
}
const generateDatasets = ({ annualConst }: IGenerationDatasets) => {
  const {
    totalAnnualCostIncreasePercent,
    totalAnnualCostOriginalPercent,
    totalAnnualCostChangePercent,
    totalAnnualCarbonChangePercent,
    totalAnnualCarbonOriginalPercent,
    totalAnnualCarbonIncreasePercent
  } = annualConst;

  let result = [];

  const carbonCostLabels = [
    "Carbon",
    "Removed Carbon",
    "Extra Carbon",
    "Cost",
    "Reduced Cost",
    "Additional Cost"
  ];
  const carbonChartData = [
    Number(totalAnnualCarbonOriginalPercent.toFixed(2)),
    Number(totalAnnualCarbonChangePercent.toFixed(2)),
    Number(totalAnnualCarbonIncreasePercent.toFixed(2)),
    0,
    0,
    0
  ];
  const costChartData = [
    0,
    0,
    0,
    Number(totalAnnualCostOriginalPercent.toFixed(2)),
    Number(totalAnnualCostChangePercent.toFixed(2)),
    Number(totalAnnualCostIncreasePercent.toFixed(2))
  ];

  const chartColors = ["grey", "#e6e6e6", "black", "#d14343", "#d47979", "red"];

  for (let i = 0; i < 6; i++) {
    result.push({
      label: carbonCostLabels[i],
      yAxesID: "stackedCapY",
      borderWidth: 2,
      order: 3,
      borderColor: chartColors[i],
      backgroundColor: chartColors[i],
      data: [carbonChartData[i], costChartData[i]]
    });
  }

  return result;
};
