import { memo, useCallback, useEffect, useState } from "react";
import ReactSlider from "react-slider";
import styled from "styled-components";
import { throttle } from "underscore";
import styles from "./Slider.module.css";

const StyledThumb = styled.div`
  height: 77%;
  line-height: 22px;
  cursor: grab;
  align-items: center;
  display: flex;
  outline: none;
  width: 40px;
  justify-content: center;
`;
interface IThumb {
  props: any;
  state: {
    index: number;
    value: number[];
    valueNow: number;
  };
  color: string;
}
const Thumb = ({ props, state, color }: IThumb) => (
  <StyledThumb {...props}>
    <div
      className={styles.thumbMarkerBefore}
      style={{ backgroundColor: color }}
    ></div>
    <div
      className={styles.thumbMarker}
      style={{ backgroundColor: color }}
    ></div>

    <div
      style={
        color === "yellow"
          ? { border: "1px solid black", color: "black", background: color }
          : { border: "1px solid white", color: "white", background: color }
      }
      className={styles.thumbText}
    >
      {state.valueNow}GW
    </div>
  </StyledThumb>
);

const StyledTrack = styled.div`
  ${(props: any) =>
    props.index === 1
      ? `
      z-index:0;
      background: ${props.color};
      height: 100%;
      margin-right: 20px;
      border-radius: 5px 0px 0px 5px;
      `
      : ""}
`;
interface ITrack {
  props: any;
  state: {
    index: number;
    value: number[];
  };
  color: string;
}
const Track = ({ props, state, color }: ITrack) => (
  <StyledTrack {...props} color={color} index={state.index} />
);

interface IDoubleSlider {
  min: number;
  max: number;
  step: number;
  values: number[];
  updateValues: (values: number[]) => void;
  color: string;
}
export const DoubleSlider = memo(
  ({ min, max, step, values, updateValues, color }: IDoubleSlider) => {
    const [sliderValues, setSliderValues] = useState(values);

    const callUpdateValues = useCallback(
      throttle((newValues: number[]) => {
        updateValues(newValues); // this setsFilteredValues = SliderValues
      }, 80),
      []
    );
    useEffect(() => {
      if (sliderValues) {
        callUpdateValues(sliderValues);
      }
    }, [callUpdateValues, sliderValues]);

    return (
      <div className={styles.rangeSlider}>
        <ReactSlider
          value={sliderValues}
          onChange={(values) => setSliderValues(values)}
          className={styles.horizontalSlider}
          step={step}
          renderThumb={(props, state) => (
            <Thumb props={props} state={state} color={color} />
          )}
          renderTrack={(props, state) => (
            <Track props={props} state={state} color={color} />
          )}
          max={max}
        />
      </div>
    );
  }
);
