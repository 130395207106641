import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef
} from 'react';
import { isMobile } from 'react-device-detect';
import { round, max, min, floor, exp, pow, log2 } from 'mathjs';
import toPrettyNumber from '../../../functions/toPrettyNumber';
import styles from './SimpleVersion.module.css';
import DayChart from '../DayChart/DayChart';
import SocialSection from '../../SocialSection/SocialSection';
import SocialPopUp from './SocialPopUp/SocialPopUp';
import {
  SimplifiedChartsTop,
  SimplifiedChartsBottom
} from './SimplifiedCharts/SimplifiedCharts';
import UserScore from './UserScore/UserScore';

import { AllInputDataContext, CalculatedDataContext } from '../../../config';
import { debounce } from 'underscore';

const SimpleVersion = ({ version }) => {
  const { simplifiedData, optionsData } = useContext(AllInputDataContext);
  const { cost, emissions, security } = simplifiedData;
  const { annualConst, reserveRemaining } = useContext(CalculatedDataContext);
  const [userScorePopup, setUserScorePopup] = useState(false);

  const timeOfPopup = useRef(Date.now() - 240000);
  let simpleDataNew = { cost, emissions, security };

  simpleDataNew.cost = simpleCost();
  simpleDataNew.emissions = simpleEmissons();
  simpleDataNew.security = simpleSecurity();
  let totalAlertLevel = round(
    100 *
      (1 - pow(simpleDataNew.cost.percentage, 2)) *
      (1 - pow(simpleDataNew.emissions.percentage, 2)) *
      (1 - pow(simpleDataNew.security.percentage, 2)),
    2
  );
  const socialScorePopup = useCallback(
    debounce((totalAlertLevel) => {
      timeOfPopup.current = Date.now();
      setUserScorePopup(true);
    }, 500),
    []
  );

  useEffect(() => {
    if (
      totalAlertLevel > optionsData.minScoreSocialPopup &&
      timeOfPopup.current + 300000 < Date.now()
    ) {
      socialScorePopup(totalAlertLevel);
    }
  }, [optionsData.minScoreSocialPopup, socialScorePopup, totalAlertLevel]);

  return (
    <div className={styles.simpleViewContainer}>
      <div className={styles.simpleChartContainer}>
        <div className={styles.simpleChartContainerTop}>
          <SimplifiedChartsTop
            name={'cost'}
            simpleData={simpleDataNew.cost}
            guageChartID={0}
            style={{ maxWidth: '25%' }}
          />
          <SimplifiedChartsTop
            name={'emissions'}
            simpleData={simpleDataNew.emissions}
            guageChartID={1}
            style={{ maxWidth: '25%' }}
          />
          <SimplifiedChartsTop
            name={'security'}
            simpleData={simpleDataNew.security}
            guageChartID={2}
            style={{ maxWidth: '25%' }}
          />
        </div>
        <div className={styles.simpleChartContainerBottom}>
          <SimplifiedChartsBottom
            name={'cost'}
            simpleData={simpleDataNew.cost}
            style={{ maxWidth: '25%' }}
          />
          <SimplifiedChartsBottom
            name={'emissions'}
            simpleData={simpleDataNew.emissions}
            style={{ maxWidth: '25%' }}
          />
          <SimplifiedChartsBottom
            name={'security'}
            simpleData={simpleDataNew.security}
            style={{ maxWidth: '25%' }}
          />
        </div>
      </div>
      <div
        className={styles.detailedViewContainer}
        style={isMobile ? { minWidth: '100%' } : {}}
      >
        <div className={styles.socialScore}>
          <SocialSection />
          <UserScore
            userScore={round(totalAlertLevel)}
            optionsData={optionsData}
            setUserScorePopup={setUserScorePopup}
          />
        </div>

        <DayChart dayTypeNumber={optionsData.noOfDayTypes} version={version} />
        <DayChart
          dayTypeNumber={optionsData.noOfDayTypes + 1}
          version={version}
        />
      </div>
      {userScorePopup && (
        <SocialPopUp
          userScore={round(totalAlertLevel)}
          optionsData={optionsData}
          setUserScorePopup={setUserScorePopup}
        />
      )}
    </div>
  );

  function simpleCost() {
    let simpleData = {};

    let costValue = annualConst.totalAnnualCost * cost.converstionFactor;

    let calculatePercentage = min(
      1,
      max(
        0,

        (costValue - cost.minValue) / (cost.maxValue - cost.minValue)
      )
    );

    simpleData.realsticValue =
      optionsData.currency +
      toPrettyNumber(round(costValue * cost.realityFactor));

    simpleData.actualValue = round(costValue, 2) + cost.displayUnits;
    simpleData.percentage = calculatePercentage;
    let imgNumber = round(simpleData.percentage * 5);
    simpleData.alertLevel = imgNumber;
    simpleData.img = {
      src: `/images/cost/${imgNumber}.jpg`
    };

    return simpleData;
  }

  function simpleEmissons() {
    let simpleData = {};

    let emissionsValue =
      annualConst.totalAnnualCarbon * emissions.converstionFactor;

    simpleData.percentage = min(
      1,
      max(
        0,
        1 + (1 / 6) * log2((max(0, emissionsValue) + 1) / emissions.maxValue)
      )
    );

    let imgNumber = round(simpleData.percentage * 5);

    simpleData.actualValue = round(emissionsValue) + emissions.displayUnits;

    simpleData.realsticValue = (
      <>{toPrettyNumber(round(emissionsValue * 0.25 + 20))}cm</>
    );
    simpleData.alertLevel = imgNumber;
    simpleData.img = {
      src: `/images/emissions/${imgNumber}.jpg`
    };

    return simpleData;
  }
  function simpleSecurity() {
    let simpleData = {};

    simpleData.percentage = max(
      0,

      1 -
        (reserveRemaining - security.minValue) /
          (security.maxValue - security.minValue)
    );

    const getImageAddress = () => {
      let imgNumber = reserveRemaining + 5;
      if (reserveRemaining >= 10) {
        imgNumber = 16;
      } else if (reserveRemaining >= -5) {
        imgNumber = reserveRemaining + 5;
      } else {
        imgNumber = 0;
      }

      let imgNumberBO = 'blackout' + imgNumber;
      return `/images/security/${imgNumberBO}.png`;
    };

    simpleData.img = { src: getImageAddress(), width: '', height: '200px' };

    simpleData.actualValue =
      max(-100, reserveRemaining) + security.displayUnits;

    simpleData.realsticValue =
      toPrettyNumber(
        max(
          0,
          min(8760, round(exp(2.6 - (13.67 * reserveRemaining) / 100) - 3))
        )
      ) + 'hrs';

    simpleData.percentage = min(1, simpleData.percentage);
    simpleData.alertLevel = floor(simpleData.percentage * 5.9999);
    return simpleData;
  }
};
export default SimpleVersion;
