import { memo, useContext, useMemo } from "react";
import { AllInputDataContext } from "../../config";
import { IMEGSData, IMEGSFilteringParameters } from "../../types/MEGS.types";
import { MEGSFiltering } from "./MEGSFiltering";
import styles from "./MEGSCharts.module.css";
import { Scatter } from "react-chartjs-2";
import { MEGSChartData, MEGSChartOption } from "./MEGSChartConfig";
import { Link } from "react-router-dom";
import { ChartData, ChartOptions } from "chart.js";
import { isMobileOnly } from "react-device-detect";
import { ImgWithFallback } from "../../functions/ImgWithFallback";

interface IMEGSCharts {
  MEGSFilteringParameters: IMEGSFilteringParameters[];
}
export const MEGSCharts = memo(({ MEGSFilteringParameters }: IMEGSCharts) => {
  const megs: IMEGSData[] = useContext(AllInputDataContext).megsData;
  const results = useMemo(() => MEGSFiltering(megs), [megs]);

  const chartData: ChartData = MEGSChartData(results, MEGSFilteringParameters);
  const chartOptions: ChartOptions = useMemo(() => MEGSChartOption(), []);

  return (
    <div className={styles.MEGSContainer}>
      <ImgWithFallback
        src={"/images/landingPage/MEGS_ICON.webp"}
        fallback={"/images/landingPage/MEGS_ICON.png"}
        alt="Megs Icon Logo"
        className={styles.backgroundIcon}
      />
      <ImgWithFallback
        src={"/images/landingPage/megs_background.webp"}
        fallback={"/images/landingPage/megs_background.png"}
        alt="Megs Background"
        className={styles.backgroundGraph}
      />
      <div className={styles.headingImgContainer}>
        <ImgWithFallback
          src={"/images/landingPage/MEGS_Logo_1_White.webp"}
          fallback={"/images/landingPage/MEGS_Logo_1_White.png"}
          alt="Megs White Logo"
          className={styles.headingImg}
        />
      </div>
      {isMobileOnly && (
        <p style={{ textAlign: "center", margin: "5px" }}>
          <strong>Rotate mobile portrait to see filters</strong>
        </p>
      )}
      <p>
        MEGS models three components essential for the delivery of electricity,
        Energy, Frequency Response and Inertia, the latter two being essential
        grid services. A detailed examination of the algorithm can be found in
        this{" "}
        <Link
          style={{ color: "black" }}
          to="/publications/#MEGS:%20Modelling%20energy%20and%20grid%20services%20to%20explore%20decarbonisation%20of%20power%20systems%20at%20lowest%20total%20system%20cost"
        >
          paper
        </Link>
        .
        <br /> <br />
        By changing the constrains on the left you can explore the results of
        3,300 scenarios for a low carbon grid in Australia’s National
        Electricity Market, in particular seeing how they compare on a cost vs
        decarbonisation chart. The data and methodology are explained in more
        detail in this{" "}
        <Link
          style={{ color: "black" }}
          to="/publications/#The%20Lowest%20Total%20System%20Cost%20NEM:%20The%20impact%20of%20constraints"
        >
          report
        </Link>
        .
      </p>
      <div className={styles.chartsContainer}>
        <div className={styles.chartInnerCard}>
          {/* @ts-ignore */}
          <Scatter data={chartData} options={chartOptions} />
        </div>
      </div>
    </div>
  );
});
