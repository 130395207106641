import React, { useContext, memo, useMemo } from 'react'
import ReactSlider from 'react-slider'

import styled from 'styled-components'

import styles from './Slider.module.css'
import { AllInputDataContext } from './../../../config'

const thumbStyle = props => `
  height: 77%;
  line-height: 22px;
  cursor:grab;
  align-items: center;
  display: flex;
  outline: none;
width:40px;
    justify-content: center;
`

const StyledThumb = styled.div`
  ${props => thumbStyle(props)}
`
const Thumb = ({ props, state, color, name }) => (
  <StyledThumb {...props} color={color} name={name}>
    <div
      className={styles.thumbMarkerBefore}
      style={{ backgroundColor: color }}
    ></div>
    <div
      className={styles.thumbMarker}
      style={{ backgroundColor: color }}
    ></div>

    <div
      style={
        name === 'Solar'
          ? { border: '1px solid black', color: 'black', background: color }
          : { border: '1px solid white', color: 'white', background: color }
      }
      className={styles.thumbText}
    >
      {state.valueNow}GW
    </div>
  </StyledThumb>
)

const trackBar = color => `
      z-index:0;
      background: ${color};
      height: 100%;
      margin-right: 20px;
      border-radius: 5px 0px 0px 5px;
      `

const StyledTrack = styled.div`
  ${props => (props.index === 1 ? '' : trackBar(props.color))}
`

const Track = ({ props, state, color }) => (
  <StyledTrack {...props} color={color} index={state.index} />
)

const Slider = memo(
  ({ value, originalValue, techIndexNumber, updateSliderValues }) => {
    const { technologyData } = useContext(AllInputDataContext)
    let initalSliderPosition

    initalSliderPosition = useMemo(
      () => (originalValue * 84) / technologyData[techIndexNumber].capMax + 8,
      [originalValue, techIndexNumber, technologyData]
    )

    return (
      <>
        <div className={styles.rangeSlider}>
          <div
            className={styles.thumbMarkerInital}
            style={{
              backgroundColor: technologyData[techIndexNumber].color,
              left: initalSliderPosition + '%'
            }}
          ></div>
          <ReactSlider
            value={value}
            onChange={val => updateSliderValues(val, techIndexNumber)}
            className={styles.horizontalSlider}
            step={0.1}
            renderThumb={(props, state) => (
              <Thumb
                props={props}
                state={state}
                color={technologyData[techIndexNumber].color}
                name={technologyData[techIndexNumber].name}
              />
            )}
            renderTrack={(props, state) => (
              <Track
                props={props}
                state={state}
                color={technologyData[techIndexNumber].color}
              />
            )}
            max={+technologyData[techIndexNumber].capMax}
          />
        </div>
      </>
    )
  }
)

export default Slider
