import type { ChartData, ChartOptions } from 'chart.js';
import { IMEGSData, IMEGSFilteringParameters } from '../../types/MEGS.types';

export const MEGSChartOption = (): ChartOptions => {
  return {
    animation: false,
    responsive: true,
    maintainAspectRatio: true,
    elements: {
      point: {
        radius: 2
      }
    },
    scales: {
      y: {
        title: {
          display: true,
          text: 'Total System Cost ($/MWh)',
          font: { size: 16 }
        }
      },
      x: {
        title: {
          display: true,
          text: 'Percentage Decarbonisation',
          font: { size: 16 }
        },
        type: 'linear',
        position: 'bottom'
      }
    }
  };
};

const formatData = (dataSet: IMEGSData[]) =>
  dataSet.map((LD) => {
    return { x: LD.decarb * 100, y: LD.cost };
  });
const calculateLine = (MEGSData: IMEGSData[]) => {
  if (!MEGSData.length) return [];
  const minCostDataset = MEGSData.reduce((acc, curr) => {
    return curr.cost < acc.cost ? curr : acc;
  }, MEGSData[0]);

  const lineDataset: IMEGSData[] = [minCostDataset];

  let allPointsExamined = false;

  while (!allPointsExamined) {
    let minGradient: number | null = null;

    allPointsExamined = true;
    const nextDatapoint = MEGSData.reduce<null | IMEGSData>((acc, curr) => {
      const lastDataset = lineDataset.length - 1;
      if (curr.decarb <= lineDataset[lastDataset].decarb) return acc;

      const gradient =
        (curr.cost - lineDataset[lastDataset].cost) /
        (curr.decarb - lineDataset[lastDataset].decarb);
      if (minGradient === null || gradient < minGradient) {
        minGradient = gradient;
        return curr;
      }
      return acc;
    }, null);

    if (!nextDatapoint) {
      return formatData(lineDataset);
    } else {
      allPointsExamined = false;
      lineDataset.push(nextDatapoint);
    }
  }
  return [];
};

export const MEGSChartData = (
  MEGSData: IMEGSData[],
  MEGSFilteringParameters: IMEGSFilteringParameters[]
): ChartData => {
  const data = MEGSData.map((MD) => {
    return { x: MD.decarb * 100, y: MD.cost };
  });
  const MEGSDataFiltered = MEGSData.reduce((acc: IMEGSData[], curr) => {
    let shouldInclude = true;
    MEGSFilteringParameters.forEach((MEGSFilteringParameter) => {
      if (
        curr[MEGSFilteringParameter.techName] <
          MEGSFilteringParameter.values[0] ||
        curr[MEGSFilteringParameter.techName] > MEGSFilteringParameter.values[1]
      )
        shouldInclude = false;
    });

    if (shouldInclude) return [...acc, curr];

    return acc;
  }, []);

  const filteredDataForChart = formatData(MEGSDataFiltered);

  return {
    datasets: [
      {
        label: 'Original Dataset',
        data,
        order: 3,
        borderColor: 'rgb(130, 179, 219)',
        backgroundColor: 'rgb(130, 179, 219)'
      },
      {
        label: 'Filtered Dataset',
        data: filteredDataForChart,
        order: 2,
        borderColor: 'rgb(255, 0, 0)',
        backgroundColor: 'rgb(255, 0, 0)'
      },
      {
        type: 'line',
        label: 'Original Frontier',
        order: 1,
        borderColor: 'black',
        backgroundColor: 'rgb(130, 179, 219)',
        data: calculateLine(MEGSData),
        fill: false,
        tension: 0.4
      },
      {
        type: 'line',
        label: 'Filtered Frontier',
        order: 0,
        borderColor: 'rgb(115, 17, 17)',
        backgroundColor: 'rgb(255, 0, 0)',
        data: calculateLine(MEGSDataFiltered),
        fill: false,
        tension: 0.4
      }
    ]
  };
};
