import React, { memo } from 'react'
import { round } from 'mathjs'
import allStyles from '../../AllCharts.module.css'
import styles from './UserScore.module.css'

const UserScore = memo(({ userScore, optionsData, setUserScorePopup }) => {
  const alertLevel = 'alertLevel' + round(((100 - userScore) * 4.9999) / 100)

  return (
    <div
      className={styles.userScoreWrapper}
      onClick={() => setUserScorePopup(true)}
    >
      <div
        className={allStyles.chartInnerCard}
        style={{
          backgroundColor: optionsData[alertLevel],
          color: 'white',
          height: '100%'
        }}
      >
        <div className={styles.userScoreInnerContainer}>
          <h1>{userScore}%</h1>
          <h3>Share Your Score</h3>
        </div>
      </div>
    </div>
  )
})

export default UserScore
