import React, { useContext, memo, useMemo } from 'react'
import { round } from 'mathjs'
import styles from './SliderDisplay.module.css'
import { AllInputDataContext } from '../../../config'

const SliderDisplay = memo(
  ({ value, techIndexNumber, technologyCost, handleInputChange }) => {
    const { technologyData, optionsData } = useContext(AllInputDataContext)
    const isGenerating = technologyCost > 0

    const pettyTechnologyCost = useMemo(() => {
      let cost = technologyCost

      if (cost >= 1000) {
        cost = round(cost / 1000, 2) + 'B'
      } else if (cost === 0) {
      } else {
        cost = round(cost, 2) + 'M'
      }
      return optionsData.currency + cost
    }, [optionsData.currency, technologyCost])

    const techTitleLowercase = technologyData[techIndexNumber].name
      .toLowerCase()
      .replace(/\s/g, '')

    return (
      <div
        className={styles.capacityDisplay}
        style={
          isGenerating
            ? {
                borderColor: technologyData[techIndexNumber].color
              }
            : {}
        }
      >
        <div className={styles.sliderInputArea} style={{ display: 'flex' }}>
          <input
            id={techTitleLowercase + '-range-input'}
            type='text'
            inputMode='numeric'
            value={value}
            onChange={event =>
              handleInputChange(event.target.value, techIndexNumber)
            }
          />

          <span className={styles.inputSuffix}>GW</span>
        </div>
        <div className={styles.costTooltip}>
          <span className={styles.costTooltiptext}>
            Capex + Running + Fixed Cost
          </span>
          <span id={techTitleLowercase + 'Cost'}>{pettyTechnologyCost}</span>
        </div>
      </div>
    )
  }
)
export default SliderDisplay
