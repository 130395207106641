import axios from 'axios';

const getDataFromDB = ({ dataRequested }) => {
  console.log('DB START');

  return new Promise(async (resolve, reject) => {
    let api = `${process.env.REACT_APP_SERVER_IP}/api?country=${dataRequested.country}&year=${dataRequested.year}`;
    try {
      axios.get(api).then((res) => {
        console.log('DB COMPLETE', res.data);
        return resolve(res.data);
      });
    } catch (error) {
      return reject('API get Error', error);
    }
  });
};

export default getDataFromDB;
