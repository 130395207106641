import { debounce, clone } from 'underscore';
import queryString from 'query-string';
export const updateAddressBar = debounce(
  ({
    reqUpdateAddressBar,
    setUserLink,
    sliderValues,
    dataRequested,
    version,
    contentToDisplay,
    pageMode,
    MEGSFilteringParameters
  }) => {
    if (sliderValues !== undefined && reqUpdateAddressBar.current) {
      let userValues = clone(sliderValues);
      userValues.splice(8, 1);
      let page;
      if (!pageMode) {
        page = 'charts';
      } else {
        page = contentToDisplay;
      }
      if (contentToDisplay !== 'home') {
        let newQuery = `?`;

        const queryValues = {
          page,
          version,
          country: dataRequested.country,
          year: dataRequested.year
        };
        if (dataRequested.dataset === 'megs') {
          MEGSFilteringParameters.forEach(
            (item) => (queryValues[item.techName] = item.values)
          );
        }
        if (dataRequested.dataset === 'esx')
          queryValues.sliderValues = userValues;

        newQuery =
          newQuery +
          queryString.stringify(queryValues, {
            arrayFormat: 'comma'
          });

        setUserLink(`https://modelling.energy/${newQuery}`);
        window.history.pushState({ page: 1 }, 'userValues', newQuery);
      }
    }
    reqUpdateAddressBar.current = true;
  },
  500
);
