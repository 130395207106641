import React, { useContext, memo } from 'react'
import { Bar } from 'react-chartjs-2'
import {
  carbonCostChartOption,
  carbonCostChartData
} from './carbonCostChartData'
import { CalculatedDataContext, AllInputDataContext } from '../../../config'
import styles from './../AllCharts.module.css'

const CarbonCostGenerationChart = memo(() => {
  const { annualConst } = useContext(CalculatedDataContext)
  const { optionsData } = useContext(AllInputDataContext)

  const chartData = carbonCostChartData({ annualConst, optionsData })
  const chartOptions = carbonCostChartOption({ optionsData })

  return (
    <div className={styles.dataChart}>
      <div className={styles.chartInnerCard}>
        <Bar data={chartData} options={chartOptions} />
      </div>
    </div>
  )
})

export default CarbonCostGenerationChart
