import React, { memo } from "react";
import styles from "./PageContentStyle.module.css";
export const DataContent = memo(({ country }: { country: "uk" | "aus" }) => {
  if (country === "uk") {
    return (
      <div
        className={styles.contentContainer}
        style={{ width: "90%", borderColor: "#c70000" }}
      >
        <h3>Availabilities</h3>
        <p>
          These are all taken to be 90% of capacity with the following
          exceptions:
        </p>
        <ul>
          <li>
            Nuclear is taken as 75%, a consistent value achieved by EU reactors
            and just below reported factor for UK plant in 2015-17 (Chart 3 –
            Special Feature Nuclear Electricity in UK on DUKES website).
          </li>
          <li>
            Biomass is set as 70%. This is derived from its main components:
            wood pellet plants are assumed to be 90% available and EfW/ Landfill
            gas which have load factors around 30-40% according to Energy
            Trends.
          </li>
          <li>
            Wind, solar and hydro availability profiles are based on Gridwatch
            generation data for 2017 Q2 – 2020 Q1 scaled by load factors taken
            from Energy Trends over the same period. For wind the model does not
            distinguish between onshore and offshore but assumes a 3:4 ratio as
            currently seen in the UK.
          </li>
        </ul>
        <h3>Starting Capacities.</h3>
        <p>
          These are based on capacities supplying the grid in December 2019
          (excluding industrial autogeneration and some smaller generation).
          Interconnectors and the demand they supply are not modelled.
        </p>

        <p></p>
        <ul>
          <li>
            Renewables are taken from Energy Trends Q4 2019 table 6.1. Biomass
            here is aggregate of plant matter and all waste streams.
          </li>
          <li>
            Gas is major CCGT and dual fired steam plant as defined in DUKES
            table 5.7 for Dec 2018,assuming no changes over 2019.
          </li>
          <li>
            Coal is Ratcliffe, Drax (2 units), West Burton and Fiddlers Ferry (3
            units).
          </li>
          <li>
            Batteries are quoted as having a capacity of 900MW according to
            Solar Power Portal’s interpretation of the UK Battery Storage
            Project Database Report.
          </li>
        </ul>
        <h3>Days Modelled</h3>
        <p>
          In this website application, the year is split into two seasons to
          pick up the most important seasonal variations. Within each season,
          there are four day types, three represent high, medium and low
          renewable input, with the final day representing the worst couple of
          days in that season in terms of proportion of demand which can be
          supplied by renewables. These were derived from the “NEM Review Data”
          for January 2014 to December 2016. Assuming 30 GW of PV and Wind are
          built, days were sorted into the top, middle, and bottom 33% for daily
          demand net of renewables, with the very worst 1% forming the final
          category. Demand, and Wind, PV and hydro load factors were averaged
          across the days in each category for the 8 points in a day.
        </p>
        <h3>Costs</h3>
        <p>
          For generation technologies these are based on the BEIS publication
          “Electricity Generation Costs 2020” Published August 2020. In some
          cases this document refers back to the previous version compiled by
          Parsons Brinkerhoff in 2016. The model works on three cost parameters,
          the sum of an annualised capex in £/kW (for increases in capacity
          only), fixed costs in £/kW and a variable cost in £/MWh. These values
          were taken from the reports, not the LCOE values that were calculated
          from these base data.
        </p>
        <p>
          Exceptions to using these data are for nuclear in 2020’s and storage
          (which are not in the report). Nuclear for 2020 is based on Hinkley,
          which seem much more realistic as the only new nuclear this decade.
          2050 costs for nuclear are based on the report with its 30% capex
          reduction. Batteries are based on the reported costs of the large
          Hornsdale 1& 2 in South Australia and a smaller community energy
          scheme in the UK. These were re-scaled to 4 hour costs assuming all
          costs are associated with storage. Pumped storage is based on the
          reported costs for building Corrie Glas, a 30 GWh proposed scheme in
          Scotland.{" "}
        </p>
        <p>
          For all calculations a discount rate of 8% is used to annualise capex.
          Coal and gas costs are taken from BEIS Fossil Fuel Price Assumptions
          2019, published February 2020, using central forecasts and the nearest
          year available. No carbon price is added to the fuel costs, so the
          model will display costs without any externalities associated with
          Climate Change.
        </p>
        <p>Detailed data on costs can be found in the tables below:</p>
        <table>
          <thead>
            <tr>
              <th>UK Costs 2020</th>
              <th>Capex (£/kW)</th>
              <th>Fixed (£/kW/yr)</th>
              <th>Running (£/MWh)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Nuclear</td>
              <td>4340</td>
              <td>73</td>
              <td>10</td>
            </tr>
            <tr>
              <td>Wind</td>
              <td>1375</td>
              <td>30</td>
              <td>5</td>
            </tr>
            <tr>
              <td>Solar</td>
              <td>450</td>
              <td>7</td>
              <td>0</td>
            </tr>
            <tr>
              <td>Hydro</td>
              <td>3260</td>
              <td>26</td>
              <td>6</td>
            </tr>
            <tr>
              <td>Gas CCS</td>
              <td>1510</td>
              <td>26</td>
              <td>39</td>
            </tr>
            <tr>
              <td>Biomass (coal conversion)</td>
              <td>280</td>
              <td>23</td>
              <td>76</td>
            </tr>
            <tr>
              <td>Gas</td>
              <td>415</td>
              <td>8</td>
              <td>38</td>
            </tr>
            <tr>
              <td>Coal</td>
              <td>0</td>
              <td>39</td>
              <td>21</td>
            </tr>
            <tr>
              <td>Battery (4h)</td>
              <td>667</td>
              <td>14</td>
              <td>0</td>
            </tr>
            <tr>
              <td>Pumped Storage (24h)</td>
              <td>640</td>
              <td>16</td>
              <td>0</td>
            </tr>
          </tbody>
        </table>

        <br />
        <br />
        <table>
          <thead>
            <tr>
              <th>UK Costs 2050</th>
              <th>Capex (£/kW)</th>
              <th>Fixed (£/kW/yr)</th>
              <th>Running (£/MWh)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Nuclear</td>
              <td>4340</td>
              <td>73</td>
              <td>10</td>
            </tr>
            <tr>
              <td>Wind</td>
              <td>1125</td>
              <td>23</td>
              <td>5</td>
            </tr>
            <tr>
              <td>Solar</td>
              <td>350</td>
              <td>6</td>
              <td>0</td>
            </tr>
            <tr>
              <td>Hydro</td>
              <td>3260</td>
              <td>26</td>
              <td>6</td>
            </tr>
            <tr>
              <td>BECCS</td>
              <td>3190</td>
              <td>146</td>
              <td>104</td>
            </tr>
            <tr>
              <td>Gas CCS</td>
              <td>1310</td>
              <td>22</td>
              <td>51</td>
            </tr>
            <tr>
              <td>Biomass (coal conversion)</td>
              <td>280</td>
              <td>23</td>
              <td>76</td>
            </tr>
            <tr>
              <td>Gas</td>
              <td>415</td>
              <td>8</td>
              <td>51</td>
            </tr>
            <tr>
              <td>Battery (4h)</td>
              <td>180</td>
              <td>14</td>
              <td>0</td>
            </tr>
            <tr>
              <td>Pumped Storage (24h)</td>
              <td>640</td>
              <td>16</td>
              <td>0</td>
            </tr>
          </tbody>
        </table>
        <h3>
          <strong>Carbon intensity</strong>
        </h3>
        <p>
          The carbon emissions from nuclear and variable renewables are assumed
          to be zero in the model. Coal and gas are based on well known carbon
          intensity factors for the fuel and the assumed efficiencies. Biomass
          is the most contentious with estimates that could be negative through
          to it being worse that coal. In this model it is assumed the biomass
          is of low carbon intensity (otherwise there would be no point burning
          it to reduce emissions) from good sources. According to Stephenson and
          MacKay of DECC there appear to be many sources with a fuel intensity
          of &lt;=100 g/kWh, so a value of 50 is taken, combined with an
          efficiency of 35% this gives a carbon intensity of{" "}
          <strong>0.15 t/MWh.</strong>
        </p>
        <br />
      </div>
    );
  }
  return (
    <div
      className={styles.contentContainer}
      style={{ width: "90%", borderColor: "#c70000" }}
    >
      <h3>Availabilities</h3>
      <p>
        There are all taken to be 90% of capacity with the following exceptions:
      </p>
      <ul>
        <li>
          Nuclear is taken as 75%, a consistent value achieved by EU reactors
          and just below reported factor for UK plant in 2015-17 (Chart 3 –
          Special Feature Nuclear Electricity in UK on DUKES website).
        </li>
        <li>Biomass represents Energy from Waste and is set as 70%.</li>
        <li>
          Wind and solar availability profiles are based on Renewables Ninja (
          <a
            href="www.renewables.ninja"
            rel="noopener noreferrer"
            target="_blank"
          >
            www.renewables.ninja
          </a>
          ) load factors for 2013-2015. Hydro is based on generation reported to
          NEM Review 2013-2015.
        </li>
      </ul>
      <h3>Starting Capacities.</h3>
      <p>
        These are based on capacities supplying the grid in December 2019, taken
        from AEMO’s 2019 Input and Assumptions Workbook [1], and are the sum of
        each generator type’s max capacity. With coal being taken as the sum of
        all supercritical and subcritical Brown and black coal.
      </p>
      <h3>Carbon Intensity</h3>
      <p>
        The carbon emissions from nuclear and variable renewables are assumed to
        be zero in the model. Coal and gas carbon intensities are an average of
        the combustion emissions for each technology from AEMO’s 2019 Input and
        Assumptions Workbook [1]. <br />
        The Carbon emission for bio was taken as 15% of the actual combustion
        emissions as a scope 2 adjustment.[2] <br />
        The carbon emissions for Coal with CCS was taken from APGT at 95%
        capture.[3]
      </p>
      <h3>Days Modelled</h3>
      <p>
        In this website application, the year is split into two seasons to pick
        up the most important seasonal variations. Within each season, there are
        four day types, three represent high, medium and low renewable input,
        with the final day representing the worst couple of days in that season
        in terms of proportion of demand which can be supplied by renewables.
        These were derived from the “NEM Review Data” for January 2014 to
        December 2016. Assuming 30 GW of PV and Wind are built, days were sorted
        into the top, middle, and bottom 33% for daily demand net of renewables,
        with the very worst 1% forming the final category. Demand, and Wind, PV
        and hydro load factors were averaged across the days in each category
        for the 8 points in a day.
      </p>
      <h3>Costs</h3>
      <p>
        {" "}
        Costs are primarily based on GenCost 2020-21 [7] with a few exceptions.
        In some cases this document refers back to GenCost 2019-20. The model
        works on three cost parameters, the sum of an annualised capex in £/kW
        (for increases in capacity only), fixed costs in £/kW and a variable
        cost in £/MWh. These values were taken from the input data, not the LCOE
        values that were calculated from these base data.
      </p>
      <p>
        Exceptions. Carbon Capture costs were increased dramatically from
        Gencost 2019-20 to 2020-21 with no reason given. As they are now out of
        line with UK and other cost estimates BECCS is based on UK data, and
        Coal CCS based on GenCost 2019-20 [4]. Biomass is based on conversion
        from existing coal as per UK experience. Nuclear capex is from GenCost
        2020-21 but fixed and running costs are based on UK data. Pump storage
        is based on building Snowy 2.0 and associated grid connections, Marinus
        1 cable to Tasmania and sufficient schemes like Lake Cethana to provide
        24GWh.{" "}
      </p>
      <p>
        For all calculations a discount rate of 8% is used to annualise capex.
        Fuel price for black coal is $2.50/GJ, which was taken as a round number
        from AEMO’s 2019 Input and Assumptions Workbook.[1] Fuel price for brown
        coal is $0.64/GJ, which is consistent throughout AEMO’s 2019 Input and
        Assumptions Workbook[1]. All gas technologies costs have been taken from
        the ISP with the fuel price taken as $10/GJ. <br />
        <br />
        Detailed data on costs can be found in the tables below:
      </p>

      <table>
        <thead>
          <tr>
            <th>Aus Costs 2020</th>
            <th>Capex ($/kW)</th>
            <th>Fixed ($/kW/yr)</th>
            <th>Running ($/MWh)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Wind</td>
            <td>1951</td>
            <td>46</td>
            <td>3</td>
          </tr>
          <tr>
            <td>Solar</td>
            <td>1408</td>
            <td>26</td>
            <td>0</td>
          </tr>
          <tr>
            <td>Hydro</td>
            <td>1866</td>
            <td>59</td>
            <td>7</td>
          </tr>
          <tr>
            <td>Coal CCS</td>
            <td>6116</td>
            <td>77</td>
            <td>40</td>
          </tr>
          <tr>
            <td>Biomass (coal conversion)</td>
            <td>504</td>
            <td>141</td>
            <td>149</td>
          </tr>
          <tr>
            <td>Coal</td>
            <td>0</td>
            <td>59</td>
            <td>23</td>
          </tr>
          <tr>
            <td>Gas CCGT</td>
            <td>1801</td>
            <td>11</td>
            <td>82</td>
          </tr>
          <tr>
            <td>Gas OCGT</td>
            <td>961</td>
            <td>15</td>
            <td>131</td>
          </tr>
          <tr>
            <td>Battery (4h)</td>
            <td>1200</td>
            <td>8</td>
            <td>0</td>
          </tr>
          <tr>
            <td>Pumped Storage (24h)</td>
            <td>812</td>
            <td>18</td>
            <td>0</td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <table>
        <thead>
          <tr>
            <th>Aus Costs 2050</th>
            <th>Capex ($/kW)</th>
            <th>Fixed ($/kW/yr)</th>
            <th>Running ($/MWh)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Nuclear</td>
            <td>7244</td>
            <td>131</td>
            <td>18</td>
          </tr>
          <tr>
            <td>Wind</td>
            <td>1745</td>
            <td>46</td>
            <td>3</td>
          </tr>
          <tr>
            <td>Solar</td>
            <td>585</td>
            <td>26</td>
            <td>0</td>
          </tr>
          <tr>
            <td>Hydro</td>
            <td>1773</td>
            <td>59</td>
            <td>7</td>
          </tr>
          <tr>
            <td>BECCS</td>
            <td>5742</td>
            <td>263</td>
            <td>187</td>
          </tr>
          <tr>
            <td>Coal CCS</td>
            <td>6116</td>
            <td>77</td>
            <td>40</td>
          </tr>
          <tr>
            <td>Biomass (coal conversion)</td>
            <td>504</td>
            <td>41</td>
            <td>137</td>
          </tr>
          <tr>
            <td>Gas</td>
            <td>1301</td>
            <td>13</td>
            <td>107</td>
          </tr>
          <tr>
            <td>Battery (4h)</td>
            <td>324</td>
            <td>8</td>
            <td>0</td>
          </tr>
          <tr>
            <td>Pumped Storage (24h)</td>
            <td>812</td>
            <td>18</td>
            <td>0</td>
          </tr>
        </tbody>
      </table>
      <h3>
        <strong>Reference</strong>
      </h3>
      <ol>
        <li>
          Australian Energy Market Operator (AEMO),{" "}
          <i>2019 Input and Assumptions Workbook Sept</i> 19. 2019.
        </li>
        <li>
          Stephenson, A.L. and D.J.C. MacKay,{" "}
          <i>Life Cycle Impacts of Biomass Electricity in 2020.</i> 2014.
        </li>
        <li>
          CO2CRC, <i>Australian Power Generation Technology Report.</i> 2015:
          Melbourne, Australia.
        </li>
        <li>
          Graham, P., et al., <i>GenCost 2019-20. 2020: Australia.</i>
        </li>
        <li>
          International Renewable Energy Agency (IRENA),{" "}
          <i>Renewable Power Generation Costs in 2019.</i> 2020, Abu Dhabi:
          International Renewable Energy Agency.
        </li>
        <li>
          Graham, P., et al., <i>GenCost 2018.</i> 2018: Australia.
        </li>
      </ol>
      <br />
    </div>
  );
});
