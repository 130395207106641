import React, { useContext } from 'react';

import DayChart from './DayChart/DayChart';
import CapacityChart from './CapacityChart/CapacityChart';
import AnnualGenerationChart from './AnnualGenerationChart/AnnualGenerationChart';
import CarbonCostChart from './CarbonCostChart/CarbonCostChart';

import SimpleVersion from './SimpleVersion/SimpleVersion';
import styles from './MECharts.module.css';

import { AllInputDataContext, CalculatedDataContext } from '../../config';
import ReserveChart from './ReserveChart/ReserveChart';

export default function MECharts({ version }) {
  const { optionsData } = useContext(AllInputDataContext);

  const { reserveRemaining } = useContext(CalculatedDataContext);

  const blackoutPercentage = () => {
    if (reserveRemaining >= 0) {
      return 0;
    } else {
      let blackoutPercentage = 1 - 1 / -reserveRemaining;

      if (blackoutPercentage < 1) {
        return blackoutPercentage;
      } else {
        return 1;
      }
    }
  };

  return (
    <>
      <div
        id='suttyid'
        className={styles.chartWrapper}
        style={{ backgroundColor: 'rgba(0,0,0,' + blackoutPercentage() + ')' }}
      >
        {version === 'educational' ? (
          <SimpleVersion version={version} />
        ) : (
          <div className={styles.chartsContainer}>
            {Array(optionsData.noOfDayTypes)
              .fill()
              .map((a, index) => {
                //a is a placeholder
                return <DayChart dayTypeNumber={index} key={`${index}`} />;
              })}

            <CapacityChart />
            <AnnualGenerationChart />
            <ReserveChart />
            <CarbonCostChart />
          </div>
        )}
      </div>
    </>
  );
}
