export const publicationsData = [
  {
    id: 14,
    title: '3 Horse Race',
    description: `If we chose only one technology class to decarbonise the grid, be that gas, renewables & storage or nuclear, the “winner” depends on where you draw the finish line. However, if you think about the ‘race’ to decarbonise the grid more like a team sport, you will need lots of different players with different skills to complete your team – you get a much better result. Decarbonising the electricity grid at the lowest Total System Cost will take a team of technologies.`,
    url: '3_horse_race.pdf',
    thumbnail: 'three_horse_race.jpg',
  },
  {
    id: 13,
    title: 'Keeping the Lights on in a Decarbonised NEM',
    description: `Australia Federal and State Governments have made pledges to comprehensively transition the National Energy Market from its current suite of technologies to a highly renewable suite. A core element of this is the Australian Energy Market Operators’ 2022 Integrated Systems Plan (or ISP for short) which details a rapid energy transformation towards net zero emissions, focussing on renewable energy and essential transmission. The intention is to provide consumers with reliable, and secure and affordable power. \nThe scenarios that AEMO have put forward project highly renewable futures with rapid closures of unabated coal plants, with some State governments recently announcing even more aggressive coal closures and renewables buildouts. The current and future changes in the technology mix mean that the rules and mechanisms to manage power system security also need to change. Keeping the lights on and transitioning the generation fleet is not as simple as some have made it out to be. \n The importance of understanding how a system dominated by wind and solar generation capacity will impact the reliability of the whole system, and in particular the impact on the total system cost, should be key metrics for system stakeholders. However, with much of the focus of recent work seemingly ignoring a lowest total system cost approach, this work has sought to frame several very important questions:
    1. What is required to get through a year with varying lulls in wind and solar output?
    2. What is the impact of different capital cost projections on the asset portfolio that delivers the lowest cost system?
    3. Will the lights stay on if we follow AEMO’s Step Change Scenario?
    4. Is there a better asset portfolio that keeps the lights on, achieves targeted emissions reduction and keeps total systems costs as low as possible?
    In short, we have sought to present an alternative portfolio that minimises the total system cost, and challenges some of the assumed outcomes others are putting forward.
    `,
    url: 'keeping_the_lights_on_in_a_decarbonised_nem_dec_2022.pdf',
    thumbnail: 'keeping_the_lights_on_in_a_decarbonised_nem_dec_2022.jpg',
  },
  {
    id: 12,
    title:
      'Characterisation and mitigation of renewable droughts in the Australian National Electricity Market',
    description:
      'In a decarbonising world, the electricity generation mix in Australia’s National Electricity Market (NEM) is likely to be heavily dependent on wind and solar. Designing an electricity system dominated by variable renewable energy generation requires careful examination of periods of low renewable output to ensure storage or other back up generation is sufficient to avoid loss of load. This study uses 15 years of climate and electricity demand data to examine the frequency and nature of the occurrence of low renewable periods. It examines strategies for their mitigation so that unserved energy standards are not breached. We have found that the winter period, May to August, is the time where the NEM is at greatest risk of loss of load. This winter period is when the demand in southern Australian states is higher, solar generation is lower and a series of low wind periods can drain storage. It has been demonstrated that any proposed generation mix reliant on renewable energy generation should be stress tested across a low wind winter, like the complex winter of 2010, not just a single isolated low wind period. Storage was found to be ideal to provide energy for a few hours overnight, but firm dispatchable thermal generation is likely to be a lower cost option than long term storage for extended low wind periods. Diversifying generation with the addition of offshore wind may reduce the need for storage, although the need for floating wind turbines may make this alternative too expensive to add any value in the Australian context.',
    url: 'Boston_2022_Environ._Res._Commun._4_031001.pdf',
    thumbnail: 'Boston_2022_Environ._Res._Commun._4_031001.jpg',
  },
  {
    id: 11,
    title:
      'Total Systems Cost: A Better Metric for Valuing Electricity in Supply Network Planning and Decision-Making',
    description:
      'Comparing the cost and value of electricity generation technologies is becoming more complex as electricity grids become more diverse. The changing generation mix, along with the need to maintain a competent grid, is resulting in previously acceptable cost comparison metrics being used outside of their limited range of applicability. In particular Levelised Cost of Energy (LCOE), if used to evaluate options for a future, low carbon grid, could result in erroneous and misleading conclusions. Electricity generation facilities do not only provide energy, they also provide an array of additional services which are fundamental to maintaining a permanent and reliable electricity supply across the system, including reserve capacity, and voltage and frequency control. Indeed, some technology options pro- vide these services without generating any energy. These services, corresponding costs and operational implications need to be included in the evaluation of technologies in order to ensure the grids emerge transformed, resilient and genuinely sustainable. Total system cost and its derivative metrics are the most appropriate economic metrics for analysis and decision making in a future for low carbon grid.',
    url: 'TotalSystemCost.pdf',
    thumbnail: 'TotalSystemCost.jpg',
  },
  {
    id: 10,
    title:
      'MEGS: Modelling energy and grid services to explore decarbonisation of power systems at lowest total system cost',
    description:
      'Finding the generation mix with the lowest total system cost for deep levels of decarbonisation is critical for electricity consumers and taxpayers, who together need to cover the costs of the entire electricity system. MEGS is an electricity system scenario tool designed to explore options to approach the optimal mix for a particular decarbonisation target. A future system must also maintain system security and “keep the lights on”. To ensure this, MEGS also models grid services, such as firm capacity, inertia and frequency response, ensuring that there are sufficient volumes of these balancing mechanisms available to the grid operator. MEGS has been validated against outturn generation data for the Australian National Electricity Market and has been used to explore the lowest cost frontier at high levels of decarbonisation.',
    url: 'MEGS.pdf',
    thumbnail: 'MEGS.jpg',
    secondaryLinkText: 'Try Out MEGS',
    secondaryLinkUrl: '/MEGS',
  },
  {
    id: 9,
    title:
      'Decarbonised Electricity. The Lowest Cost Path to Net Zero Emissions',
    description:
      'The purpose of this book is to clear the air on key aspects of grid technology assessment and give some insight into what a future grid may look like. This is not an easy task, as comparisons of the cost and value of electricity generation resources for the NEM have become increasingly complex.\nChanges in the market’s mix of generation, plus the public and political focus on the need to maintain a fit-forpurpose system, mean that cost comparison metrics used in the past have become less useful today.',
    url: 'Decarbonised_Electricity.pdf',
    thumbnail: 'Decarbonised_Electricity.jpg',
  },
  {
    id: 8,
    title: 'The Lowest Total System Cost NEM: The impact of constraints',
    description:
      'This study highlights the need for firm zero-carbon dispatchable generation to support the NEM.  It also clearly shows that a net-zero grid will be much more expensive, the total system of today’s grid is ~$11Bn/y – this will TRIPLE by 2050 with very deep decarbonisation.\nRestricting VRE, CCS or nuclear has a mostly modest impacts, but no CCS means a ~$5Bn/y impact.  Excluding both CCS and nuclear results in a very large increase in TSC at 99% decarbonisation.',
    url: 'NEM_Constraints.pdf',
    thumbnail: 'NEM_Constraints.jpg',

    secondaryLinkText: 'Try adding your own constraints to MEGS',
    secondaryLinkUrl: '/MEGS',
  },
  {
    id: 7,
    title: 'Managing Flexibility Whilst Decarbonising Electricity: Full Report',
    description:
      'The National Electricity Market has embarked upon a pathway to a gradual decarbonisation, driven at State and Federal Governments and some by commitments made in Paris.\nThis report gives insights into a new modelling approach and initial results to encourage a different energy conversation.',
    url: 'Managing_Flexibility_NEM_2017_Report.pdf',
    thumbnail: 'Managing_Flexibility_NEM_2017_Report.jpg',
  },
  {
    id: 6,
    title: 'Managing Flexibility Whilst Decarbonising Electricity: Summary',
    description:
      'The National Electricity Market has embarked upon a pathway to a gradual decarbonisation, driven at State and Federal Governments and some by commitments made in Paris.\nThis summary highlights the key messges and gives insights into a new modelling approach.',
    url: 'Managing_Flexibility_NEM_2017_Brochure.pdf',
    thumbnail: 'Managing_Flexibility_NEM_2017_Brochure_Summary.jpg',
  },
  {
    id: 5,
    title:
      'Finding the Right Balance: Power System Flexibility in an Era of Decarbonisation: An Annotated Bibliography',
    description:
      'Our report in partnership with Red Vector has been chosen as part of a series of reports that "the Energy Policy Institute of Australia believes may influence future policymaking and ought therefore to be taken into account."  This latest MEGS report seeks to examine a “very high renewables world” that minimises fossil fuel consumption whilst aiming for 90% decarbonisation. The end result is that there still isn\'t a silver bullet - a diverse grid is needed.',
    url: '3_19_Robert_Pritchard_PP.pdf',
    thumbnail: '3_19_Robert_Pritchard_PP.jpg',
  },
  {
    id: 4,
    title: 'Snowy 2.0 and Beyond: The Value of Large-Scale Energy Storage',
    description:
      'This study has examined the impact of Snowy 2.0 and the Battery of The Nation, as well as scenarios beyond these two projects, to examine what benefit large scale pumped hydro storage could provide to the NEM as it decarbonises.  In line with previous studies, the analysis undertaken focuses on total system cost (TSC) and CO2 emission reductions as the key metrics.  Decarbonisation is assumed to be the objective and TSC optimised, as this is what the consumer will ultimately have to fund.',
    url: 'Snowy_2_0_and_Beyond.pdf',
    thumbnail: 'Snowy_2_0_and_Beyond.jpg',
  },
  {
    id: 3,
    title: 'What Happens When We Add Big Infrastructure To The NEM?',
    description:
      'The purpose of this study was to examine the effect of four substantial upgrades and how they may impact the shape of the anticipated decarbonisation transformation.  When comparing the four  infrastructure upgrade options, it was clear that they offered very different options and services to the grid and result in different impacts / benefits to the system.',
    url: 'NEM_Infrastructure_vA.pdf',
    thumbnail: 'NEM_Infrastructure_vA.jpg',
  },
  {
    id: 2,
    title: 'Score Sheet - the NEM 2019',
    description:
      'How much does a technology actually cost to add to a power grid? Well that depends. On the Australian National Energy Market - the System Cost of Replacement Electricity is described using the NEW and exciting method.',
    url: 'GET_SCoRE_2019.pdf',
    thumbnail: 'GET_SCoRE_2019.jpg',
  },
  {
    id: 1,
    title: "Effect of RET's on the NEM 2018",
    description:
      'Most Australian states and territories have relied on coal fired power for their electricity supplies. More recently, Qld, Vic, SA and ACT have adopted policies to increase their electricity from renewable energy and targeting net zero emissions aspirations. How this impacts on the physical operation of the NEM has been examined in this work, takeing account of the enduring need for grid strength and reliability services.',
    url: 'Effect_of_the_RET_on_the_NEM_2019.pdf',
    thumbnail: 'Effect_of_the_RET_on_the_NEM_2019.jpg',
  },
];
