import {
  useState,
  Dispatch,
  SetStateAction,
  useContext,
  useCallback
} from "react";
import { AllInputDataContext } from "../../config";
import {
  IMEGSFilteringParameters,
  IMEGSTechnologyData
} from "../../types/MEGS.types";
import { DoubleSlider } from "./Slider/DoubleSlider";
import styles from "./MEGSControls.module.css";
import { isMobileOnly } from "react-device-detect";

interface IMEGSControls {
  MEGSFilteringParameters: IMEGSFilteringParameters[];
  setMEGSFilteringParameters: Dispatch<
    SetStateAction<IMEGSFilteringParameters[]>
  >;
}
export const MEGSControls = ({
  MEGSFilteringParameters,
  setMEGSFilteringParameters
}: IMEGSControls) => {
  const technologies: IMEGSTechnologyData[] =
    useContext(AllInputDataContext).megsTechnologies;

  const [selectedTech, setSelectedTech] = useState<string>("");

  const updateValues = useCallback(
    (values: number[], param: IMEGSFilteringParameters) => {
      setMEGSFilteringParameters((prevState) => {
        const newState = [...prevState];
        const index = newState.findIndex((state) => state.id === param.id);

        if (index !== -1) newState[index].values = values;
        return newState;
      });
    },

    [setMEGSFilteringParameters]
  );

  const handleAdd = useCallback(() => {
    const techToAdd = technologies.find((tech) => tech.id === +selectedTech);
    if (techToAdd) {
      setMEGSFilteringParameters((prevState) => {
        return [
          ...prevState,
          { ...techToAdd, values: [techToAdd.min, techToAdd.max] }
        ];
      });
      setSelectedTech("");
    }
  }, [selectedTech, setMEGSFilteringParameters, technologies]);

  const handleRemove = useCallback(
    (param: IMEGSTechnologyData) => {
      setMEGSFilteringParameters((prevState) => {
        const newState = [...prevState];

        const index = newState.findIndex((tech) => tech.id === param.id);
        if (index !== -1) {
          newState.splice(index, 1);
        }
        return newState;
      });
      setSelectedTech("");
    },
    [setMEGSFilteringParameters]
  );

  return (
    <>
      {isMobileOnly && (
        <p style={{ textAlign: "center", margin: "5px" }}>
          Rotate mobile landscape to see chart
        </p>
      )}
      <div className={styles.selectorRow}>
        <select
          placeholder="Select a parameter"
          value={selectedTech}
          onChange={(event) => setSelectedTech(event.target.value)}
        >
          <option value="" disabled>
            Select a parameter
          </option>
          {technologies.map((tech) => {
            const isTechInFilter = MEGSFilteringParameters.find(
              (t) => t.id === tech.id
            );
            if (isTechInFilter) return null;

            return <option value={tech.id}>{tech.label}</option>;
          })}
        </select>
        <button
          className={styles.addButton}
          disabled={MEGSFilteringParameters.length > 4}
          onClick={handleAdd}
        >
          Add
        </button>
      </div>
      {MEGSFilteringParameters.map((param) => {
        return (
          <div className={styles.controller} key={param.id}>
            <h4 className={styles.controllerLabel}>{param.label}</h4>
            <h5 className={styles.remove} onClick={() => handleRemove(param)}>
              Remove
            </h5>
            <DoubleSlider
              min={param.min}
              max={param.max}
              values={param.values}
              step={param.step}
              color={param.color}
              updateValues={(values) => updateValues(values, param)}
            />
            <p className={styles.controllerDescripton}>{param.description}</p>
          </div>
        );
      })}
    </>
  );
};
