import React, { memo } from "react";
import { isMobile } from "react-device-detect";
import classNames from "classnames";
import styles from "./ButtonTogglePair.module.css";
const ToggleButton = memo(({ label, onClick, isSelected }) => {
  return (
    <button
      style={isMobile ? { fontSize: "0.8em" } : {}}
      className={classNames([styles.button, isSelected && styles.selected])}
      onClick={onClick}
    >
      {label}
    </button>
  );
});

export default function ButtonTogglePair({
  options,
  value,
  setValue,
  valueToUpdate,
  getNewDBData,
}) {
  // useCallback = 'remember' a function
  // useMemo = 'remember' a value
  // memo = wrap a function/component in it, and it wont re-render if props = same (shallow)

  const handleClick = (value) => {
    if (getNewDBData) {
      setValue((v) => ({
        ...v,
        [valueToUpdate]: value,
      }));
    } else {
      setValue((v) => ({
        ...v,
        [valueToUpdate]: value,
      }));
    }
  };

  return (
    <div className={styles.buttonPair}>
      {options.map(({ label, value: optionValue }, index) => (
        <ToggleButton
          key={`option_${index}`}
          label={label}
          onClick={() => handleClick(optionValue)}
          isSelected={value[valueToUpdate] === optionValue}
        />
      ))}
    </div>
  );
}
