import type { ChartData, ChartOptions } from "chart.js";
import type {} from "chart.js";
import { ChartDataset } from "chart.js";

interface IDayChartOption {
  dayTypeNumber: any;
  allInputData: any;
  displayAxis: any;
}
export function dayChartOption({
  dayTypeNumber,
  allInputData,
  displayAxis
}: IDayChartOption): ChartOptions {
  const dayTypeLabels = Object.keys(allInputData.dayTypeData);
  const { optionsData } = allInputData;
  return {
    animation: false,
    // responsiveAnimationDuration: 0,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        font: { size: 14, weight: "bold" },

        color: "black",
        text: dayTypeLabels[dayTypeNumber],
        padding: 15
      },
      legend: {
        display: false
      }
    },
    elements: {
      point: {
        radius: 0,
        hitRadius: 4
      }
    },
    scales: {
      y: {
        type: "linear",
        grid: {
          display: true
        },
        title: {
          display: displayAxis(), //See Lines 177 for True.
          text: "Generation & Demand (GW)",
          padding: 0,
          font: { size: 14 }
        },
        // order: 0,
        stacked: true,
        beginAtZero: true,
        max: optionsData.dayChartMaxValue,
        ticks: {
          display: displayAxis(), //This turns off scale numbers
          stepSize: 10
        }
      },
      yUnStacked: {
        display: false,
        // order: 2,
        stacked: false,
        beginAtZero: true,
        max: optionsData.dayChartMaxValue
      },
      x: {
        ticks: {
          font: { size: 12 },
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0
        }
      }
    }
  };
}
interface IDayChartData {
  dayTypeNumber: any;
  allInputData: any;
  calculatedData: any;
}
export function dayChartData({
  dayTypeNumber,
  allInputData,
  calculatedData
}: IDayChartData): ChartData {
  const { optionsData, technologyData } = allInputData;
  return {
    labels: allInputData.optionsData.timeSlotLabels.split(","),
    datasets: generateDatasets({
      optionsData,
      technologyData,
      dayTypeNumber,
      calculatedData
    })
  };
}

interface IGenerateDatasets {
  optionsData: any;
  technologyData: any;
  dayTypeNumber: any;
  calculatedData: any;
}
const generateDatasets = ({
  optionsData,
  technologyData,
  dayTypeNumber,
  calculatedData
}: IGenerateDatasets): ChartDataset[] => {
  let result: ChartDataset[] = [];

  let curtailmentIndex = technologyData.length;
  let demandIndex = technologyData.length + 1;

  const buildDataSet = (technology: any, index: any): ChartDataset => {
    return {
      label: technology.name,
      tension: 0.4,
      borderWidth: 2,
      order: 3,
      fill: true,
      borderColor: technology.color,
      backgroundColor: technology.color,
      data: [
        ...calculatedData.dayChartData[dayTypeNumber][index],
        calculatedData.dayChartData[dayTypeNumber][index][0]
      ]
    };
  };
  for (let index = 0; index < calculatedData.dataIndex.blackout; index++) {
    result.push(buildDataSet(technologyData[index], index));
  }

  for (
    let index = optionsData.noOfTechs - optionsData.noOfStorTypes;
    index < optionsData.noOfTechs;
    index++
  ) {
    result.push(buildDataSet(technologyData[index], index));
  }

  result.push(
    buildDataSet(
      technologyData[calculatedData.dataIndex.blackout],
      calculatedData.dataIndex.blackout
    )
  );

  result.push({
    label: "Curtailment",
    tension: 0.4,
    borderWidth: 1,
    fill: true,
    borderColor: "pink",
    backgroundColor: "pink",
    data: [
      ...calculatedData.dayChartData[dayTypeNumber][curtailmentIndex],
      calculatedData.dayChartData[dayTypeNumber][curtailmentIndex][0]
    ],
    order: 3
  });

  result.push({
    label: "Demand",
    tension: 0.4,
    yAxisID: "yUnStacked",
    borderColor: "black",
    backgroundColor: "transparent",
    data: [
      ...calculatedData.dayChartData[dayTypeNumber][demandIndex],
      calculatedData.dayChartData[dayTypeNumber][demandIndex][0]
    ],
    type: "line",
    // this dataset is drawn on top
    order: 0
  });

  return result;
};
