import React, { useContext, memo } from 'react'
import { Bar } from 'react-chartjs-2'
import { capacityChartOption, capacityChartData } from './capacityChartData'

import { AllInputDataContext, SliderValuesContext } from '../../../config'
import styles from './../AllCharts.module.css'

const CapacityChart = memo(() => {
  const allInputData = useContext(AllInputDataContext)
  const sliderValues = useContext(SliderValuesContext)

  const chartData = capacityChartData({
    allInputData,
    sliderValues
  })
  const chartOptions = capacityChartOption()

  return (
    <div className={styles.dataChart}>
      <div className={styles.chartInnerCard}>
        <Bar data={chartData} options={chartOptions} />
      </div>
    </div>
  )
})
export default CapacityChart
