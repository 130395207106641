import React, { Dispatch, SetStateAction, memo } from "react";

import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AboutUsContent } from "./AboutUsContent";
import { AlgorithmContent } from "./AlgorithmContent";
import { EnergyGeeksContent } from "./EnergyGeeksContent";
import { ReferencesContent } from "./ReferencesContent";
import { DataContent } from "./DataContent";
import { ContactUsContent } from "./ContactUsContent";
import styles from "./GenericPage.module.css";
import { PublicationsContent } from "./PublicationsContent";

type ContentToDisplay =
  | "aboutus"
  | "algorithm"
  | "energygeeks"
  | "references"
  | "data"
  | "contactus"
  | "publications";
const GenericPage = memo(
  ({
    setPageMode,
    contentToDisplay,
    country,
  }: {
    setPageMode: Dispatch<SetStateAction<boolean>>;
    contentToDisplay: ContentToDisplay;
    country: "uk" | "aus";
  }) => {
    let title = "";
    let content;

    if (contentToDisplay === "aboutus") {
      title = "About Us";
      content = <AboutUsContent />;
    }
    if (contentToDisplay === "algorithm") {
      title = "Algorithm";
      content = <AlgorithmContent />;
    }

    if (contentToDisplay === "energygeeks") {
      title = "Energy Geeks";
      content = <EnergyGeeksContent />;
    }
    if (contentToDisplay === "references") {
      title = "References";
      content = <ReferencesContent />;
    }
    if (contentToDisplay === "data") {
      title = country.toUpperCase() + " Data";
      content = <DataContent country={country} />;
    }
    if (contentToDisplay === "contactus") {
      title = "Contact Us";
      content = <ContactUsContent />;
    }
    if (contentToDisplay === "publications") {
      title = "Publications";
      content = <PublicationsContent />;
    }

    return (
      <>
        <div className={styles.pageContainer}>
          <h1 className={styles.controlTitle}>{title}</h1>
          {content}
          <div onClick={() => setPageMode(false)}>
            <h2
              className={styles.controlCloseText}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "2em",
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon className="nav-icon" icon={faAngleUp} />
              Close
            </h2>
          </div>
        </div>
      </>
    );
  }
);
export default GenericPage;
