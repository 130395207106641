import type { ChartData, ChartOptions } from "chart.js";

interface IGenerationChartOption {
  annualConst: any;
}

export function generationChartOption({
  annualConst
}: IGenerationChartOption): ChartOptions {
  const { annualDemandOriginal } = annualConst;

  return {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      annotation: {
        annotations: {
          1: {
            type: "line",
            scaleID: "y",
            value: annualDemandOriginal,
            borderColor: "black",
            borderWidth: 2,
            label: {
              backgroundColor: "black",
              font: { size: 10, weight: "bold", family: "sans-serif" },
              color: "white",
              xPadding: 3,
              yPadding: 3,
              cornerRadius: 3,
              position: "center",
              enabled: true,
              content: "Demand"
            }
          }
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          offset: false
        }
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: "Annual Generation & Demand (TWh)",
          padding: 0,
          font: { size: 12 }
        }
      }
    }
  };
}
interface IGenerationChartData {
  allInputData: any;
  annualConst: any;
}
export function generationChartData({
  allInputData,
  annualConst
}: IGenerationChartData): ChartData {
  return {
    labels: ["Generation"],
    datasets: generateDatasets({ allInputData, annualConst })
  };
}

const generateDatasets = ({
  allInputData,
  annualConst
}: IGenerationChartData) => {
  let result = [];
  let index = 0;

  for (const technology of allInputData.technologyData) {
    if (technology.name !== "Blackout") {
      result.push({
        label: technology.name,

        borderWidth: 2,
        order: 3,
        borderColor: technology.color,
        backgroundColor: technology.color,
        data: [annualConst.annualTechGen[index]]
      });
    }
    index++;
  }

  return result;
};
