import { memo } from "react";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router";
import { publicationsData } from "../../publicationsData";
import styles from "./PublicationsSegment.module.css";

export const PublicationsSegment = memo(() => {
  const history = useHistory();
  return (
    <div id="publications" className={styles.container}>
      <picture style={{ width: "100%" }}>
        <source
          srcSet={"/images/landingPage/publicationsBackground.webp"}
          type={"image/webp"}
        />
        <img
          src={"/images/landingPage/publicationsBackground.png"}
          alt="Publications Green Background"
          className={styles.backgroundImage}
        />
      </picture>
      <h1>Publications</h1>

      <div className={styles.contentContainer}>
        <div
          onClick={() =>
            history.push(`/publications/#${publicationsData[0].title}`)
          }
          className={styles.mainImageContainer}
        >
          <h3>Lastest Publications</h3>
          <h4>{publicationsData[0].title}</h4>
          <img
            src={`/publications/thumbnails/${publicationsData[0].thumbnail}`}
            alt=""
            className={styles.img}
          />
        </div>
        <div className={styles.textContent}>
          <p>
            <strong>
              Red Vector and Gamma Energy Technology are keen that at much as
              possible of their research is publicly available, none of it is
              hidden behind a paywall. Feel free to browse and download
              publications here.
            </strong>
          </p>

          <button
            className={styles.button}
            onClick={() => history.push("/publications")}
          >
            More on Publications
          </button>

          <div className={styles.publicationsList}>
            {publicationsData
              .filter((a, index) => index < 4)
              .map((pub) => (
                <div
                  onClick={() => history.push(`/publications/#${pub.title}`)}
                >
                  <img
                    height={!isMobile ? "200px" : "130px"}
                    src={`/publications/thumbnails/${pub.thumbnail}`}
                    alt="Decarbonised Electricity Report"
                    className={styles.thumbnail}
                  />
                </div>
              ))}
          </div>
          <div className={styles.publicationsList}>
            {publicationsData
              .filter((a, index) => index > 4)
              .map((pub) => (
                <div
                  onClick={() => history.push(`/publications/#${pub.title}`)}
                >
                  <img
                    height={!isMobile ? "200px" : "110px"}
                    src={`/publications/thumbnails/${pub.thumbnail}`}
                    alt="Decarbonised Electricity Report"
                    className={styles.thumbnail}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
});
