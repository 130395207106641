import React, { memo, useContext } from 'react';
import { Line } from 'react-chartjs-2';
import { dayChartOption, dayChartData } from './dayChartData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { AllInputDataContext, CalculatedDataContext } from '../../../config';
import allStyles from './../AllCharts.module.css';
import styles from './DayChart.module.css';
import { sum } from 'mathjs';

const ToolTip = memo(({ name, description }) => {
  return (
    <div className={styles.chartsTooltip}>
      <FontAwesomeIcon icon={faInfoCircle} className={styles.chartsInfoIcon} />
      <span className={styles.chartsTooltiptext}>
        <p>
          <b>{name}</b>
          <br />
          {description}
        </p>
      </span>
    </div>
  );
});

const DayChart = memo(({ dayTypeNumber, version }) => {
  const allInputData = useContext(AllInputDataContext);
  const calculatedData = useContext(CalculatedDataContext);

  const displayAxis = () => {
    if (window.innerWidth < 600) {
      return true;
    } else if (
      dayTypeNumber === 0 ||
      dayTypeNumber === 4 ||
      version === 'educational'
    ) {
      return true;
    } else return false;
  };

  const chartData = dayChartData({
    dayTypeNumber,
    allInputData,
    calculatedData
  });

  const chartOptions = dayChartOption({
    dayTypeNumber,
    allInputData,
    displayAxis
  });
  const isBlackout = () => {
    return (
      calculatedData.annualConst.averageGenPerDay[
        calculatedData.dataIndex.blackout
      ][dayTypeNumber] > 0 ||
      sum(
        calculatedData.dayChartData[dayTypeNumber][
          calculatedData.dataIndex.blackout
        ]
      ) > 0
    );
  };
  return (
    <div
      className={
        version === 'educational' ? styles.simpleAverageChart : styles.dayChart
      }
    >
      <div className={allStyles.chartInnerCard}>
        <ToolTip
          name={allInputData.allDayData[dayTypeNumber].name}
          description={allInputData.allDayData[dayTypeNumber].description}
        />
        {isBlackout() && <p className={styles.blackout}>BLACKOUT</p>}

        <Line data={chartData} options={chartOptions} />
      </div>
    </div>
  );
});
export default DayChart;
