import React, { useContext, memo } from 'react';
import { Bar, Chart } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import {
  generationChartOption,
  generationChartData
} from './annualGenerationChartData';
import { AllInputDataContext, CalculatedDataContext } from '../../../config';
import styles from './../AllCharts.module.css';
import styles2 from '../DayChart/DayChart.module.css';
Chart.register(annotationPlugin);

const ToolTip = memo(({ name, description }) => {
  return (
    <div className={styles2.chartsTooltip}>
      <FontAwesomeIcon icon={faInfoCircle} className={styles2.chartsInfoIcon} />
      <span className={styles2.chartsTooltiptext}>
        <p>
          <b>{name}</b>
          <br />
          {description}
        </p>
      </span>
    </div>
  );
});

const AnnualGenerationChart = memo(() => {
  const allInputData = useContext(AllInputDataContext);
  const { annualConst } = useContext(CalculatedDataContext);

  const chartData = generationChartData({ annualConst, allInputData });
  const chartOptions = generationChartOption({ annualConst });

  return (
    <div className={styles.dataChart}>
      <div className={styles.chartInnerCard}>
        <ToolTip
          name='Curtailment'
          description={`Annual Curtailment: ${annualConst.curtailment} TWh`}
          color='black'
        />
        <Bar data={chartData} options={chartOptions} />
      </div>
    </div>
  );
});
export default AnnualGenerationChart;
