import React, { memo } from 'react';
import styles from './Nav.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory } from 'react-router-dom';

const Nav = memo(
  ({
    navRequired,
    setNavRequired,
    navWidth,
    version,
    handleVersionSwitch,
    setPageMode,
    setContentToDisplay,
    country,
    dataset,
    setDataRequested
  }) => {
    const history = useHistory();

    const PageLink = ({ children, content }) => (
      <div
        onClick={() => {
          setContentToDisplay(content);
          setPageMode(true);
          window.scrollTo(0, 0);
        }}
      >
        {children}
      </div>
    );

    const CustomLink = ({ children, onClick }) => (
      <div onClick={onClick}>{children}</div>
    );
    return (
      <div
        className={styles.navContainer}
        style={
          navRequired
            ? { height: '350px', width: navWidth }
            : { width: navWidth }
        }
      >
        <>
          <FontAwesomeIcon
            className={styles.navClose}
            icon={faTimes}
            onClick={() => setNavRequired(false)}
          />
          <div className={styles.nav}>
            <PageLink content='aboutus'>About Us</PageLink>
            <PageLink content='references'> References</PageLink>
            <Link to='/publications'> Publications</Link>
            {dataset === 'esx' && (
              <>
                <PageLink content='algorithm'>Algorithm</PageLink>
                <PageLink content='energygeeks'> Energy Geeks</PageLink>
                <PageLink content='data'>{country.toUpperCase()} Data</PageLink>
                <CustomLink
                  onClick={() => {
                    handleVersionSwitch();
                    setPageMode(false);
                    window.scrollTo(0, 0);
                  }}
                >
                  {version === 'educational' ? 'Scientific' : 'Educational'}{' '}
                  Version
                </CustomLink>
              </>
            )}

            <CustomLink
              onClick={() => {
                setDataRequested((prevState) => {
                  return {
                    ...prevState,
                    country: 'aus',
                    year: 2050,
                    dataset: dataset === 'esx' ? 'megs' : 'esx'
                  };
                });
                history.push(dataset === 'esx' ? 'MEGS' : 'ESx');
                setContentToDisplay('charts');
                setPageMode(false);
                setNavRequired(false);
                window.scrollTo(0, 0);
              }}
            >
              {' '}
              Switch to {dataset === 'esx' ? 'MEGS' : 'ESx'}
            </CustomLink>

            <PageLink content='contactus'> Contact Us</PageLink>
          </div>
        </>
      </div>
    );
  }
);
export default Nav;
