import { useEffect } from 'react';
import getQueryVariable from '../functions/getQueryVariable';

export const useHistoryHandeller = ({
  history,
  sliderValues,
  setSliderValues,
  dataRequested,
  setDataRequested,
  setVersion,
  setContentToDisplay,
  setPageMode,
  reqUpdateAddressBar
}) =>
  useEffect(() => {
    //Handles History
    return history.listen(() => {
      if (history.action === 'POP') {
        const {
          sliderValues: sliderValuesFromHistory,
          country: countryFromHistory,
          year: yearFromHistory,
          version: versionFromHistory,
          page: pageFromHistory
        } = getQueryVariable();

        const datasetFromHistory = window.location.pathname
          .slice(1)
          .toLowerCase();
        if (
          sliderValues !== sliderValuesFromHistory &&
          sliderValuesFromHistory
        ) {
          setSliderValues(sliderValuesFromHistory);
        }
        if (
          dataRequested.dataset !== datasetFromHistory ||
          dataRequested.year !== yearFromHistory ||
          dataRequested.country !== countryFromHistory
        ) {
          setDataRequested((prevState) => {
            return {
              dataset: datasetFromHistory || prevState.dataset,
              year: yearFromHistory || prevState.year,
              country: countryFromHistory || prevState.country
            };
          });
        }
        if (versionFromHistory) setVersion(versionFromHistory);
        if (pageFromHistory) setContentToDisplay(pageFromHistory);
        setPageMode(
          pageFromHistory &&
            pageFromHistory !== 'home' &&
            pageFromHistory !== 'charts'
        );
        reqUpdateAddressBar.current = false;
      }
    });
  }, [
    dataRequested.country,
    dataRequested.dataset,
    dataRequested.year,
    history,
    reqUpdateAddressBar,
    setContentToDisplay,
    setDataRequested,
    setPageMode,
    setSliderValues,
    setVersion,
    sliderValues
  ]);
