import { memo, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useHistory } from 'react-router';
import getQueryVariable from './functions/getQueryVariable';
import Main from './Main';
import LandingPage from './pages/LandingPage/LandingPage';
import { Publications } from './pages/Publications';

const { sliderValues: sliderValuesFromQuery = 'invalid' } = getQueryVariable();

export const App = memo(() => {
  const history = useHistory();

  useEffect(() => {
    // Redirrects old links to new ESx section
    const pushTo = sliderValuesFromQuery === 'invalid' ? null : 'esx';
    if (pushTo) {
      history.push(pushTo + '?' + window.location.search.substring(1));
    }
  }, [history]);

  return (
    <>
      <Switch>
        <Route exact path={['/esx', '/megs']}>
          <Main />
        </Route>
        <Route exact path="/publications">
          <Publications />
        </Route>
        <Route exact path="/">
          <LandingPage />
        </Route>
      </Switch>
    </>
  );
});
