import {
  faBars,
  faChalkboardTeacher,
  faChartArea,
  faHome,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import styles from './Controls.module.css';

export const ControlHeader = ({
  isMegs,
  setNavRequired,
  handleVersionSwitch,
  dataRequested,
  version,
}) => {
  return (
    <div className={styles.controlHeader}>
      <Link to="/">
        <FontAwesomeIcon icon={faHome} />
      </Link>
      <h1
        className={styles.controlHeaderTitle}
        style={{
          margin: 0,
          fontSize: 1.5 + 'em',
          color: isMegs ? 'red' : 'rgb(0 77 127)',
        }}
      >
        {isMegs ? 'MEGS' : 'ESx'}
      </h1>
      <h1
        className={styles.controlHeaderCountryDate}
        style={{
          margin: 0,
          fontSize: 1 + 'em',
          color: isMegs ? 'red' : 'rgb(0 77 127)',
        }}
      >
        {dataRequested.country}{' '}
        {dataRequested.year === 'current'
          ? dataRequested.country === 'uk'
            ? 2020
            : new Date().getFullYear()
          : dataRequested.year}
      </h1>

      {dataRequested.dataset !== 'megs' && (
        <FontAwesomeIcon
          icon={version === 'educational' ? faChartArea : faChalkboardTeacher}
          className={styles.navIcon}
          onClick={() => {
            handleVersionSwitch();
          }}
        />
      )}

      <FontAwesomeIcon
        icon={faBars}
        className={styles.navIcon}
        onClick={() => {
          setNavRequired((prevState) => !prevState);
        }}
      />
    </div>
  );
};
