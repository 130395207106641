import React, { memo } from "react";
import styles from "./PageContentStyle.module.css";
export const AlgorithmContent = memo(() => {
  return (
    <div className={styles.contentContainer} style={{ borderColor: "#db8800" }}>
      <p>
        Generation is stacked to meet demand in order, with the sliders at the
        top having highest priority. The ones at the very top are all low
        carbon, ensuring they get priority dispatch.
      </p>
      <p>
        Battery storage can move energy within day. It does this on the basis of
        carbon emissions, if cycling the battery can reduce emissions within the
        day then it will operate to do that. Priority is given to supplying any
        time periods which would otherwise suffer blackouts.
      </p>
      <p>
        Pumped storage can move energy between the four day types within the
        season. For simplicity it operates at a constant level of charging or
        discharging. It will first bring the deficit of the worst day up to the
        same level as the Low Renewables Day. It will then continue taking
        energy from the High Renewable Day to supply into the two days with
        lowest renewables. When average residual demand (including pumping) of
        the High Renewables Day is the same as the Average Day (or the storage
        reaches its capacity limit) the storage algorithm is complete and no
        further actions take place.
      </p>
      <p>
        No storage is able to move energy from one season to another as any
        storage with just one cycle a year would be extremely uneconomic.
      </p>
    </div>
  );
});
