import { memo } from "react";
import styles from "./CircleHeading.module.css";

interface ICircleHeading {
  link: string;
  img: string;
  title: string;
  description: string;
}
export const CircleHeading = memo(
  ({ link, img, title, description }: ICircleHeading) => {
    return (
      <div className={styles.container}>
        <a href={link} className={styles.imgContainer}>
          <img src={img} alt="" className={styles.img} />
        </a>
        <h2>{title}</h2>

        <p>{description}</p>
      </div>
    );
  }
);
