import type { ChartData, ChartOptions } from "chart.js";
import { min } from "mathjs";

export function reserveChartOption(): ChartOptions {
  return {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          offset: false
        }
      },
      y: {
        title: {
          display: true,
          text: "Reserve (%)",
          padding: -2,
          font: { size: 14 }
        },
        max: 30,
        min: -20
      }
    }
  };
}

interface IReserveChartData {
  reservesAvali: any;
  dataIndex: any;
  optionsData: any;
  annualConst: any;
  reserveRemaining: any;
  firstRunValues: any;
}

export function reserveChartData({
  reservesAvali,
  dataIndex,
  optionsData,
  annualConst,
  reserveRemaining,
  firstRunValues
}: IReserveChartData): ChartData {
  let scenarioSystemColor;
  if (firstRunValues.originalReserves * 100 <= 0) {
    scenarioSystemColor = "red";
  } else if (firstRunValues.originalReserves * 100 <= optionsData.minReserve) {
    scenarioSystemColor = "orange";
  } else {
    scenarioSystemColor = "green";
  }

  let systemColor;
  let minReserveAvalible = min(reservesAvali);
  if (
    minReserveAvalible < 0 ||
    annualConst.annualTechGen[dataIndex.blackout] > 0
  ) {
    systemColor = "red";
  } else if (minReserveAvalible <= optionsData.minReserve) {
    systemColor = "orange";
  } else {
    systemColor = "green";
  }

  return {
    labels: ["Reserve"],
    datasets: [
      {
        label: "My Reserve",
        borderWidth: 2,
        borderColor: systemColor,
        backgroundColor: systemColor,
        data: [reserveRemaining]
      }
    ]
  };
}
