import {
  faChalkboardTeacher,
  faChartArea,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { ImgWithFallback } from '../../../functions/ImgWithFallback';
import styles from './ESXSegment.module.css';

export const ESXSegment = memo(() => {
  let history = useHistory();

  return (
    <div id="ESx" className={styles.container}>
      <ImgWithFallback
        src="/images/landingPage/ME.webp"
        fallback="/images/landingPage/ME.png"
        alt="Background ESx image"
        className={styles.backgroundImage}
      />
      <h1>Electricity Scenario Explorer</h1>
      <div className={styles.contentContainer}>
        <div className={styles.imgContainer}>
          <ImgWithFallback
            src="/images/landingPage/MEOverview.webp"
            fallback="/images/landingPage/MEOverview.png"
            alt="A series of ESx screenshots"
            className={styles.img}
          />
        </div>

        <div className={styles.textContent}>
          <h1>Getting Started</h1>
          <p>
            The model has 2 modes, Educational{' '}
            <FontAwesomeIcon icon={faChalkboardTeacher} /> and Scientific{' '}
            <FontAwesomeIcon icon={faChartArea} />. Currently 2 countries are
            supported, UK and Australia. Select a flag or mode icon below to get
            started.
          </p>
          <div className={styles.controlFlags}>
            <img
              onClick={() =>
                history.push({
                  pathname: '/ESx',
                  search:
                    '?page=charts&version=educational&country=uk&year=current',
                })
              }
              src="/images/uk-flag.png"
              className={styles.flag}
              alt="United Kingdom Flag"
            />
            <div className={styles.icons}>
              {' '}
              <FontAwesomeIcon
                icon={faChalkboardTeacher}
                className={styles.icon}
                onClick={() =>
                  history.push({
                    pathname: '/ESx',
                    search:
                      '?page=charts&version=educational&country=uk&year=current',
                  })
                }
              />
              <FontAwesomeIcon
                icon={faChartArea}
                className={styles.icon}
                onClick={() =>
                  history.push({
                    pathname: '/ESx',
                    search:
                      '?page=charts&version=scientific&country=uk&year=current',
                  })
                }
              />
            </div>
          </div>
          <div className={styles.controlFlags}>
            <img
              onClick={() =>
                history.push({
                  pathname: '/ESx',
                  search:
                    '?page=charts&version=educational&country=aus&year=current',
                })
              }
              src="/images/aus-flag.png"
              className={styles.flag}
              alt="Australian Flag"
            />
            <div className={styles.icons}>
              <FontAwesomeIcon
                onClick={() =>
                  history.push({
                    pathname: '/ESx',
                    search:
                      '?page=charts&version=educational&country=aus&year=current',
                  })
                }
                icon={faChalkboardTeacher}
                className={styles.icon}
              />
              <FontAwesomeIcon
                onClick={() =>
                  history.push({
                    pathname: '/ESx',
                    search:
                      '?page=charts&version=scientific&country=aus&year=current',
                  })
                }
                icon={faChartArea}
                className={styles.icon}
              />
            </div>
          </div>
        </div>
      </div>{' '}
    </div>
  );
});
