import queryString from 'query-string';

export default function getQueryVariable() {
  const result = queryString.parse(window.location.search.substring(1), {
    arrayFormat: 'comma',
    parseNumbers: true
  });
  if (result.sliderValues !== undefined) result.sliderValues.splice(8, 0, 99);

  return result;
}
