import type { ChartData, ChartOptions } from "chart.js";

export function capacityChartOption(): ChartOptions {
  return {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          offset: false
        }
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: "Capacity (GW)",
          padding: 0,
          font: { size: 14 }
        }
      }
    }
  };
}
interface ICapacityChartData {
  allInputData: any;
  sliderValues: any;
}
export function capacityChartData({
  allInputData,
  sliderValues
}: ICapacityChartData): ChartData {
  return {
    labels: ["Capacity"],
    datasets: generateDatasets({ allInputData, sliderValues })
  };
}

const generateDatasets = ({
  allInputData,
  sliderValues
}: ICapacityChartData) => {
  let result = [];
  let index = 0;

  for (const technology of allInputData.technologyData) {
    if (technology.name !== "Blackout") {
      result.push({
        label: technology.name,
        yAxesID: "stackedCapY",
        borderWidth: 2,
        order: 3,
        borderColor: technology.color,
        backgroundColor: technology.color,
        data: [sliderValues[index]]
      });
    }
    index++;
  }

  return result;
};
