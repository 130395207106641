import React, { memo } from 'react';
import allStyles from '../MEcharts/AllCharts.module.css';
import styles from './SocialSection.module.css';

const SocialSection = memo(() => {
  return (
    <div className={styles.socialShareWraper}>
      <div className={allStyles.chartInnerCard}>
        <div className={styles.shareButtons}>
          <h3>Share our website</h3>
          <a
            target='_blank'
            rel='noreferrer'
            href='https://www.linkedin.com/shareArticle?mini=true&url=http://modelling.energy'
          >
            <img
              src='/images/social/share-on-linkedin.png'
              alt='share on linked in'
            />
          </a>
          <a
            href='https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.modelling.energy'
            target='_blank'
            rel='noreferrer'
          >
            <img
              src='/images/social/share-on-facebook.png'
              alt='share on facebook'
            />
          </a>
        </div>
      </div>
    </div>
  );
});

export default SocialSection;
