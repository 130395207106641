import React, { useContext, memo } from 'react';

import styles from './SimplifiedCharts.module.css';
import GaugeChart from 'react-gauge-chart';
import { isBrowser } from 'react-device-detect';

import { AllInputDataContext } from '../../../../config';

const SimplifiedChartsTop = memo(({ guageChartID, name, simpleData }) => {
  const { simplifiedData } = useContext(AllInputDataContext);

  return (
    <div className={styles.simplifiedChartTop}>
      <h1>{name.charAt(0).toUpperCase() + name.slice(1)}</h1>
      <div className={styles.imgContainer}>
        <img
          src={simpleData.img.src}
          alt='line drawing of a house'
          width={simpleData.img.width}
        />
      </div>
      <h3>{simpleData.realsticValue}</h3>
      <p>{simplifiedData[name].realityDescription}</p>

      <GaugeChart
        animate={false}
        className={styles.guageChartContainer}
        id={'chart' + guageChartID}
        marginInPercent={0.02}
        nrOfLevels={20}
        percent={simpleData.percentage}
        formatTextValue={() => simpleData.actualValue}
        textColor={'black'}
      />

      <p style={isBrowser ? { fontWeight: 'bold' } : {}}>
        {simplifiedData[name].guageTitle}
      </p>
    </div>
  );
});

const SimplifiedChartsBottom = memo(({ name, simpleData }) => {
  const { simplifiedData, optionsData } = useContext(AllInputDataContext);

  return (
    <div
      className={styles.simplifiedChartBottom}
      style={{
        backgroundColor: optionsData[`alertLevel${simpleData.alertLevel}`]
      }}
    >
      <p>{simplifiedData[name][`alertLevel${simpleData.alertLevel}`]}</p>
    </div>
  );
});

export { SimplifiedChartsTop, SimplifiedChartsBottom };
