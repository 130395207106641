import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo, useState } from "react";
import pagestyles from "./PageContentStyle.module.css";
import styles from "./Publications.module.css";
import { publicationsData } from "./publicationsData";

export const PublicationsContent = memo(() => {
  const [selectedPub, setSelectedPub] = useState(publicationsData[0]);
  return (
    <div
      className={pagestyles.contentContainer}
      style={{
        width: "90%",
        borderColor: "rgb(33, 134, 175)",
      }}
    >
      <p className={styles.innertitle}>
        Select a publication for more information
      </p>
      <div className={styles.publicationsList}>
        {publicationsData.map((pub) => (
          <div onMouseOver={() => setSelectedPub(pub)}>
            <img
              height="200px"
              src={`/publications/thumbnails/${pub.thumbnail}`}
              alt="Decarbonised Electricity Report"
              className={styles.thumbnail}
            />
          </div>
        ))}
      </div>
      <div className={styles.publicationInFocus}>
        <img
          onClick={() => window.open(`/publications/pdf/${selectedPub.url}`)}
          height="300px"
          src={`/publications/thumbnails/${selectedPub.thumbnail}`}
          alt="Decarbonised Electricity Report"
          className={styles.thumbnail}
        />
        <div>
          <h2
            onClick={() => window.open(`/publications/pdf/${selectedPub.url}`)}
          >
            {selectedPub.title}
          </h2>

          {selectedPub.description.split("\n").map((str) => (
            <p>{str}</p>
          ))}

          <h3
            className={styles.readMore}
            onClick={() => window.open(`/publications/pdf/${selectedPub.url}`)}
          >
            Read More{" "}
            <FontAwesomeIcon className={styles.arrow} icon={faArrowRight} />
          </h3>
        </div>
      </div>
    </div>
  );
});

export default PublicationsContent;
