import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { memo } from 'react'
import styles from './Tooltip.module.css'

const ToolTip = memo(
  ({
    name,
    description,
    color = 'rgb(27, 83, 149)',
    position = [null, '7px', null, null],
    size = '16px'
  }) => {
    return (
      <div className={styles.tooltip}>
        <FontAwesomeIcon
          style={{
            color,
            top: position[0],
            right: position[1],
            bottom: position[2],
            left: position[3],
            fontSize: size
          }}
          icon={faInfoCircle}
          className={styles.chartsInfoIcon}
        />
        <span className={styles.tooltiptext}>
          <p>
            <b>{name}</b>
            <br />
            {description}
          </p>
        </span>
      </div>
    )
  }
)

export default ToolTip
