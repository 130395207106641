import { round } from 'mathjs';
export default function calculateAnnualCurtailment({
  calcOptions,
  dayChartData
}) {
  const { noOfTechs, noOfTimeSlots, noOfDayTypes } = calcOptions;
  let annualCurtailment = 0;
  const curtailment = noOfTechs;

  // console.log(dayChartData);
  for (let i = 0; i < noOfDayTypes - 1; i++) {
 let dayTotalCurtailment  = 0
    for (let j = 0; j < noOfTimeSlots; j++) {
      // console.log(calcOptions.dayYearPercent);

     dayTotalCurtailment += dayChartData[i][curtailment][j]
      annualCurtailment +=
        dayChartData[i][curtailment][j] * calcOptions.dayYearPercent[i];
    }

    dayTotalCurtailment += ((dayTotalCurtailment/noOfTimeSlots) * calcOptions.dayYearPercent[i]*8760)/1000

  }

  // console.log('annualCurtailment (TWh)', round(annualCurtailment, 2));
  const result = round(annualCurtailment, 2)
  return result;
}
