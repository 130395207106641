import { memo, useContext, useCallback } from 'react';
import { round } from 'mathjs';
import SliderDisplay from './SliderDisplay/SliderDisplay';
import MobileTechnologyControllers from './MobileTechnologyController/MobileTechnologyController';
import Slider from './Slider/Slider';
import styles from './Controls.module.css';
import ToolTip from '../Tooltip/Tooltip';
import {
  AllInputDataContext,
  CalculatedDataContext,
  SetSliderValuesContext,
  SliderValuesContext
} from '../../config';
import { isBrowser, isTablet } from 'react-device-detect';

export const MEControls = memo(({ incrementAmmount }) => {
  const sliderValues = useContext(SliderValuesContext);

  const setSliderValues = useContext(SetSliderValuesContext);

  const { optionsData, technologyData } = useContext(AllInputDataContext);
  const { dataIndex, annualConst, firstRunValues } = useContext(
    CalculatedDataContext
  );

  const updateSliderValues = useCallback(
    (value, techIndexNumber) => {
      setSliderValues((sliderValues) => {
        let values = [...sliderValues];
        values[techIndexNumber] = value;
        return values;
      });
    },
    [setSliderValues]
  );

  const handleInputChange = useCallback(
    async (value, techIndexNumber) => {
      if (
        value > +technologyData[techIndexNumber].capMax ||
        value < 0 ||
        isNaN(value)
      ) {
        alert(
          `Value entered: "${value}" Please enter a number between 0 and ${technologyData[techIndexNumber].capMax}`
        );
      } else {
        updateSliderValues(value, techIndexNumber);
      }
    },
    [technologyData, updateSliderValues]
  );

  return (
    <div className={styles.allSliderControls}>
      <ToolTip
        name='Sliders'
        description='Slide to the left to decommission existing capacity or right to build new plant.'
        color='black'
        position={[3, 0, 0, -8]}
      />
      {[...Array(optionsData.noOfTechs)].map((x, index) => {
        if (index === dataIndex.blackout) {
          return null;
        }

        return isBrowser || isTablet ? (
          <div
            id={
              technologyData[index].name.toLowerCase().replace(/\s/g, '') +
              'Slider'
            }
            className={styles.sliderWrapper}
          >
            <label className={styles.sliderLabel}>
              {technologyData[index].name} Capacity
            </label>
            <div className={styles.sliderContainer}>
              <Slider
                techIndexNumber={index}
                value={round(sliderValues[index], 2)}
                originalValue={firstRunValues.originalSliderValues[index]}
                updateSliderValues={updateSliderValues}
                key={`SliderKey-${index}`}
              />
              <SliderDisplay
                value={sliderValues[index]}
                techIndexNumber={index}
                technologyCost={round(annualConst.annualTechCost[index])}
                handleInputChange={handleInputChange}
                key={`sliderDisplayKey-${index}`}
              />
            </div>
          </div>
        ) : (
          <MobileTechnologyControllers
            value={round(sliderValues[index], 1)}
            setSliderValues={setSliderValues}
            techIndexNumber={index}
            incrementAmmount={incrementAmmount}
            handleInputChange={handleInputChange}
            key={`${index}`}
          />
        );
      })}
    </div>
  );
});
